// based on https://www.npmjs.com/package/awesome-sigmoid
export const sigmoid = (x, gain) => (Math.tanh((gain * x) / 2) + 1) / 2;

export const makeSigmoid = ({ center, deviation, deviationOutput }) => {
  /*
    see: https://www.wolframalpha.com/input/?i=solve+y+%3D+sigmoid((g)x)+for+g
    variables: x: sigmoid input, y: sigmoid output, g: gain
  */
  const gain = Math.log((-1 * deviationOutput) / (deviationOutput - 1)) / deviation;
  return (x) => sigmoid(x - center, gain);
};

export default makeSigmoid;

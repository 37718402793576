import { useMemo, useCallback, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Row,
  Card,
  ButtonToolbar,
  ButtonGroup,
  Form,
  Tabs,
  Tab,
} from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import readXlsxFile, { readSheetNames } from 'read-excel-file';
import objectHash from 'object-hash';
import { Queue } from 'async-await-queue';

import cloneDeep from 'lodash.clonedeep';
import difference from 'lodash.difference';
import get from 'lodash.get';
import groupBy from 'lodash.groupby';
import transform from 'lodash.transform';
import uniq from 'lodash.uniq';

import { srackConsignmentUpdateSchema } from '../lib/importers/srack_consignment_update_schema';

import { renderOverlay, renderError, renderOffline } from '../components/render_helpers';
import SubmitButtonSpinner from '../components/submit_button_spinner';
import Field from '../components/form/rff_field';
import InputField from '../components/form/input_field';

import { srackImportFormPageQuery } from '../graphql/import_queries';
import { consignmentUpdate as consignmentUpdateMutation } from '../graphql/consignment_queries';
import * as updateFunctions from '../update_functions';

const consignmentQueue = new Queue(1, 100);
const priority = -1;

const SrackImportForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [fileError, setFileError] = useState('');
  const [fileRows, setFileRows] = useState([]);
  const [fileErrors, setFileErrors] = useState([]);
  const [projectCode, setProjectCode] = useState('');
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const [consignmentUpdate] = useMutation(consignmentUpdateMutation);
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    networkStatus: pageNetworkStatus,
    refetch: pageRefetch,
  } = useQuery(srackImportFormPageQuery, {
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const handleFileChange = useCallback(async (name, onChange, e) => {
    const file = get(e, 'target.files.0');
    if (file) {
      const newProjectCode = get(file.name.match(/^(\d+)\s*|-.+/), '1');
      if (newProjectCode) {
        setFileError('');
        setProjectCode(newProjectCode);
        const sheetNames = await readSheetNames(file);
        try {
          if (sheetNames.includes('WITHNUMBERS')) {
            const { rows, errors = [] } = await readXlsxFile(file, {
              schema: srackConsignmentUpdateSchema,
              sheet: 'WITHNUMBERS',
              transformData(data) {
                let newData;
                // remove the title line if exists
                if (data[0][0] !== 'Shipment N.') {
                  newData = data.slice(1);
                }
                // ignore the additional header style rows
                newData = newData.filter(
                  (d, index) => index === 0 || d[0] !== 'Shipment N.'
                );
                return newData;
              },
            });
            const importTimestamp = 'update';
            let sharedShipmentNumber;
            let sharedContainerType;
            let sharedContainerNumber;
            let sharedContainerSealNumber;
            let sharedConsignmentItemReference;
            let sharedConsignmentItemReferenceSuffix;
            const newFileRows = rows.map((row, index) => {
              const newRow = cloneDeep(row);
              const rowIndex = index + 2;
              // defaults
              if (newRow.containerReference) {
                // imported container type has some nasty unprintable unicodes
                newRow.containerReference = newRow.containerReference.replace(
                  /[^a-zA-Z0-9.()]+/gi,
                  ')'
                );
              }
              if (newRow.shipmentNumber) {
                newRow.shipmentNumber = `${newRow.shipmentNumber} (${importTimestamp})`;
              }
              // merged cell processing
              const nextRowIndex = rowIndex + 1;
              // handle merged cells in the shipmentNumber column
              const rowShipmentNumberError = errors.find(
                (error) =>
                  error.row === rowIndex &&
                  error.column === 'Shipment N.' &&
                  error.error === 'required'
              );
              const nextRowShipmentNumberError = errors.find(
                (error) =>
                  error.row === nextRowIndex &&
                  error.column === 'Shipment N.' &&
                  error.error === 'required'
              );
              if (
                newRow.shipmentNumber &&
                !rowShipmentNumberError &&
                nextRowShipmentNumberError
              ) {
                sharedShipmentNumber = newRow.shipmentNumber;
              }
              if (
                sharedShipmentNumber &&
                !newRow.shipmentNumber &&
                rowShipmentNumberError
              ) {
                newRow.shipmentNumber = sharedShipmentNumber;
              }
              if (
                sharedShipmentNumber &&
                newRow.shipmentNumber &&
                !nextRowShipmentNumberError
              ) {
                sharedShipmentNumber = '';
              }
              // handle merged cells in the containerReference column
              const rowContainerTypeError = errors.find(
                (error) =>
                  error.row === rowIndex &&
                  error.column === 'Container Type' &&
                  error.error === 'required'
              );
              const nextRowContainerTypeError = errors.find(
                (error) =>
                  error.row === nextRowIndex &&
                  error.column === 'Container Type' &&
                  error.error === 'required'
              );
              if (
                newRow.containerReference &&
                !rowContainerTypeError &&
                nextRowContainerTypeError
              ) {
                sharedContainerType = newRow.containerReference;
              }
              if (
                sharedContainerType &&
                !newRow.containerReference &&
                rowContainerTypeError
              ) {
                newRow.containerReference = sharedContainerType;
              }
              if (
                sharedContainerType &&
                newRow.containerReference &&
                !nextRowContainerTypeError
              ) {
                sharedContainerType = '';
              }

              // handle merged cells in the containerNumber column
              const rowContainerNumberError = errors.find(
                (error) =>
                  error.row === rowIndex &&
                  error.column === 'Container No.' &&
                  error.error === 'required'
              );
              const nextRowContainerNumberError = errors.find(
                (error) =>
                  error.row === nextRowIndex &&
                  error.column === 'Container No.' &&
                  error.error === 'required'
              );
              if (
                newRow.containerNumber &&
                !rowContainerNumberError &&
                nextRowContainerNumberError
              ) {
                sharedContainerNumber = newRow.containerNumber;
              }
              if (
                sharedContainerNumber &&
                !newRow.containerNumber &&
                rowContainerNumberError
              ) {
                newRow.containerNumber = sharedContainerNumber;
              }
              if (
                sharedContainerNumber &&
                newRow.containerNumber &&
                !nextRowContainerNumberError
              ) {
                sharedContainerNumber = '';
              }

              // handle merged cells in the containerSealNumber column
              const rowContainerSealNumberError = errors.find(
                (error) =>
                  error.row === rowIndex &&
                  error.column === 'Container seal No.' &&
                  error.error === 'required'
              );
              const nextRowContainerSealNumberError = errors.find(
                (error) =>
                  error.row === nextRowIndex &&
                  error.column === 'Container seal No.' &&
                  error.error === 'required'
              );
              if (
                newRow.containerSealNumber &&
                !rowContainerSealNumberError &&
                nextRowContainerSealNumberError
              ) {
                sharedContainerSealNumber = newRow.containerSealNumber;
              }
              if (
                sharedContainerSealNumber &&
                !newRow.containerSealNumber &&
                rowContainerSealNumberError
              ) {
                newRow.containerSealNumber = sharedContainerSealNumber;
              }
              if (
                sharedContainerSealNumber &&
                newRow.containerSealNumber &&
                !nextRowContainerSealNumberError
              ) {
                sharedContainerSealNumber = '';
              }

              // handle merged cells in the consignmentItemReference column
              const rowConsignmentItemReferenceError = errors.find(
                (error) =>
                  error.row === rowIndex &&
                  error.column === 'Packing Item Number' &&
                  error.error === 'required'
              );
              const nextRowConsignmentItemReferenceError = errors.find(
                (error) =>
                  error.row === nextRowIndex &&
                  error.column === 'Packing Item Number' &&
                  error.error === 'required'
              );
              if (
                newRow.consignmentItemReference &&
                !rowConsignmentItemReferenceError &&
                nextRowConsignmentItemReferenceError
              ) {
                sharedConsignmentItemReference = newRow.consignmentItemReference;
                sharedConsignmentItemReferenceSuffix = 'a';
              }
              if (
                sharedConsignmentItemReference &&
                !newRow.consignmentItemReference &&
                rowConsignmentItemReferenceError
              ) {
                newRow.consignmentItemReference = `${sharedConsignmentItemReference}${sharedConsignmentItemReferenceSuffix}`;
                sharedConsignmentItemReferenceSuffix = String.fromCharCode(
                  sharedConsignmentItemReferenceSuffix.charCodeAt(0) + 1
                );
              }
              if (
                sharedConsignmentItemReference &&
                newRow.consignmentItemReference &&
                !nextRowConsignmentItemReferenceError
              ) {
                sharedConsignmentItemReference = '';
                sharedConsignmentItemReferenceSuffix = '';
              }
              return newRow;
            });
            setFileError('');
            setFileRows(newFileRows);
            setFileErrors(errors);
            onChange(file);
          } else {
            setFileError('Spreadsheet must include a sheet titled "WITHNUMBERS"');
            setFileRows([]);
            setFileErrors([]);
          }
        } catch (err) {
          setFileError(err.message);
          setFileRows([]);
          setFileErrors([]);
        }
      } else {
        setFileError('Spreadsheet name must begin with a project code');
        setProjectCode('');
        setFileRows([]);
        setFileErrors([]);
      }
    }
  }, []);

  const onCancel = () => {
    navigate('/');
  };

  const onFormSubmit = async () => {
    const groupedFileRowsByContainerType = groupBy(fileRows, 'containerReference');

    const groupedConsignmentItemReferencesByContainerIdentifier = transform(
      groupedFileRowsByContainerType,
      (accum, v, k) => {
        const consignmentItemReferences = v.map((r) => r.consignmentItemReference).sort();
        const hash = objectHash(consignmentItemReferences);
        /* eslint-disable no-param-reassign */
        accum[hash] = { containerReference: k, consignmentItemReferences };
      },
      {}
    );
    const existingContainerIdentifiers = uniq(
      get(pageData, 'consignmentList').map((s) => s.containerIdentifier)
    );
    const rowContainerIdentifiers = Object.keys(
      groupedConsignmentItemReferencesByContainerIdentifier
    );
    const createableContainerIdentifiers = difference(
      rowContainerIdentifiers,
      existingContainerIdentifiers
    );

    if (createableContainerIdentifiers.length > 0) {
      throw new Error('Uncreated consignment identifier found.  Expected only updates');
    }
    const consignmentPromises = rowContainerIdentifiers.map(
      async (updateableContainerIdentifier) => {
        await consignmentQueue.wait(updateableContainerIdentifier, priority);
        const existingConsignment = get(pageData, 'consignmentList', []).find(
          (c) => c.containerIdentifier === updateableContainerIdentifier
        );
        if (!existingConsignment) {
          throw new Error('No existing consignment found by containerIdentifier');
        }
        const identifierData =
          groupedConsignmentItemReferencesByContainerIdentifier[
            updateableContainerIdentifier
          ];
        const { containerReference } = identifierData;
        const firstRow = fileRows.find(
          (r) => r.containerReference === containerReference
        );
        const { shipmentNumber, containerNumber, containerSealNumber } = firstRow;
        const mutationData = {
          variables: {
            id: existingConsignment.id,
            input: {
              containerIdentifier: updateableContainerIdentifier,
              shipmentNumber,
              containerReference,
              containerNumber,
              containerSealNumber,
            },
          },
          context: {
            serializationKey: settingsTenant,
            tracked: true,
            recordType: 'ConsignmentType',
            recordId: existingConsignment.id,
            mutationType: 'UPDATE',
          },
        };
        mutationData.update = updateFunctions.consignmentUpdate;
        mutationData.optimisticResponse = updateFunctions.optimisticNew({
          mutationName: 'consignmentUpdate',
          mutationData,
          currentData: existingConsignment,
        });
        try {
          const resp = await consignmentUpdate(mutationData);
          return get(resp, 'data.consignmentUpdate');
        } finally {
          consignmentQueue.end(updateableContainerIdentifier);
        }
      }
    );
    await Promise.all(consignmentPromises);
  };

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm={12}>
          <div className="float-none">
            <div className="float-start">
              <h1 className="h3 mb-3">Update Srack Shipping Import</h1>
              <p>
                This form is to update existing consignments with the container number,
                container seal number and any updates to shipment number and container
                type
              </p>
            </div>
          </div>
        </Col>
      </Row>
      {fileError && (
        <Row>
          <Col>
            <Alert variant="danger">{fileError}</Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <FinalForm
            onSubmit={(data) => onFormSubmit(data)}
            mutators={{ setFieldTouched }}
          >
            {({ handleSubmit, pristine, submitting }) => (
              <form noValidate>
                <Card>
                  <Card.Body>
                    <Field
                      type="file"
                      name="file"
                      labelWidth={4}
                      inputWidth={4}
                      size="lg"
                      component={InputField}
                      customOnChange={handleFileChange}
                    />
                    <Form.Group as={Row}>
                      <Col sm={12}>
                        <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                          <ButtonGroup className="me-2">
                            <Button
                              variant="primary"
                              onClick={() => pageRefetch()}
                              disabled={!settingsOnline}
                            >
                              Refresh
                            </Button>
                            <Button
                              variant="danger"
                              onClick={onCancel}
                              disabled={submitting}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="button"
                              variant="primary"
                              disabled={pristine || submitting}
                              onClick={handleSubmit}
                            >
                              {submitting && <SubmitButtonSpinner />}
                              {params.id ? 'Update' : 'Create'}
                            </Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                      </Col>
                    </Form.Group>
                  </Card.Body>
                </Card>
              </form>
            )}
          </FinalForm>
        </Col>
      </Row>
      {projectCode && (
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <p>{`Project Code: ${projectCode}`}</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      {fileRows.length > 0 && (
        <Row>
          <Col>
            <Tabs defaultActiveKey="rows" id="uncontrolled-tab-example" className="mb-3">
              <Tab eventKey="rows" title="Rows">
                <pre>{JSON.stringify(fileRows, undefined, 2)}</pre>
              </Tab>
              <Tab eventKey="errors" title="Errors">
                <pre>{JSON.stringify(fileErrors, undefined, 2)}</pre>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default SrackImportForm;

import { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import Chart from 'react-apexcharts';

import { sentenceCase } from 'change-case';
import get from 'lodash.get';
import defaultsDeep from 'lodash.defaultsdeep';

import defaultOptions from './default_270_radial_options';

const ElectricalSummaryRadialChart = ({ pageData, TableBuildStatuses }) => {
  const chartData = useMemo(() => {
    if (pageData?.tableSummaryReport) {
      const tableData = get(pageData, 'tableSummaryReport.report.data');
      const TableBuildStatusValues = Object.values(TableBuildStatuses);
      const contractorCompleteIndex = TableBuildStatusValues.indexOf(
        TableBuildStatuses.CONTRACTOR_ELECTRICAL_CHECKLIST_COMPLETE
      );
      const inspectorCompleteIndex = TableBuildStatusValues.indexOf(
        TableBuildStatuses.INSPECTOR_ELECTRICAL_CHECKLIST_COMPLETE
      );
      const contractorComplete =
        contractorCompleteIndex < 0
          ? 0
          : tableData
              .filter(
                (d) => TableBuildStatusValues.indexOf(d.status) >= contractorCompleteIndex
              )
              .reduce((accum, status) => accum + status.percent, 0);
      const inspectorComplete =
        inspectorCompleteIndex < 0
          ? 0
          : tableData
              .filter(
                (d) => TableBuildStatusValues.indexOf(d.status) >= inspectorCompleteIndex
              )
              .reduce((accum, status) => accum + status.percent, 0);

      const newChartData = {
        checklistComplete: contractorComplete,
        inspectorAccepted: inspectorComplete,
      };
      return newChartData;
    }
    return {};
  }, [pageData, TableBuildStatuses]);

  const series = Object.values(chartData);
  const options = defaultsDeep(
    {
      labels: Object.keys(chartData).map((k) => sentenceCase(k)),
      title: { text: 'Electrical Summary' },
    },
    defaultOptions
  );

  return (
    <Card>
      <Card.Body>
        <Chart options={options} series={series} type="radialBar" height="350" />
      </Card.Body>
    </Card>
  );
};

export default ElectricalSummaryRadialChart;

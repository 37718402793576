import { useMemo } from 'react';
import { Col, Row, Card, Button } from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { validate as uuidValidate } from 'uuid';

import LinkContainer from '../components/link_container';
import { renderOverlay, renderOffline, renderError } from '../components/render_helpers';
import ReactTable from '../components/react_table/react_table';
import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import {
  purchaserListPageQuery,
  purchaserDelete as purchaserDeleteMutation,
} from '../graphql/purchaser_queries';
import { settingsSet } from '../store/settings_slice';
import * as updateFunctions from '../update_functions';

const PurchaserList = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const [purchaserDelete] = useMutation(purchaserDeleteMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
    client: pageClient,
  } = useQuery(purchaserListPageQuery, {
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ purchaserList }) => {
      // write an individual query for every purchaser in the list
      updateFunctions.purchaserList(pageClient, purchaserList);
    },
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const purchaserDeleteClicked = async (e) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    const purchaserId = uuidValidate(dataId)
      ? dataId
      : parseInt(e.currentTarget.getAttribute('data-id'), 10);
    const mutationData = {
      variables: { id: purchaserId },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'PurchaserType',
        recordId: purchaserId,
        mutationType: 'DELETE',
      },
      update: updateFunctions.purchaserDelete,
    };
    mutationData.optimisticResponse = updateFunctions.optimistic(
      'purchaserDelete',
      mutationData
    );
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await purchaserDelete(mutationData);
        toastSuccess('Purchaser delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Purchaser delete failed');
      } finally {
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
      }
    } else {
      purchaserDelete(mutationData);
      toastWarning(`Purchaser delete ok locally. Go online to make permanent`);
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
    }
  };

  const parentColumns = [
    {
      header: 'Name',
      accessorKey: 'name',
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
  ];

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm={12}>
          <div className="float-none">
            <div className="float-start">
              <h1 className="h3 mb-3">Purchasers</h1>
            </div>
            <div className="float-end">
              <Button
                className="me-2"
                variant="primary"
                onClick={() => pageRefetch()}
                disabled={!settingsOnline}
              >
                Refresh
              </Button>
              <LinkContainer to="/purchasers/new">
                <Button variant="primary">New Purchaser</Button>
              </LinkContainer>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <ReactTable
                rootName="purchaser"
                parentColumns={parentColumns}
                data={pageData.purchaserList}
                doShow={false}
                doDelete={currentUser?.perms?.administrator}
                handleDelete={purchaserDeleteClicked}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default PurchaserList;

import { useState, useMemo, useCallback } from 'react';
import {
  Button,
  Col,
  Row,
  Card,
  ButtonToolbar,
  ButtonGroup,
  Form,
} from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useNavigate, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import arrayMutators from 'final-form-arrays';
import { v4 as uuidv4 } from 'uuid';

import cloneDeep from 'lodash.clonedeep';
import compact from 'lodash.compact';
import defaultTo from 'lodash.defaultto';
import flatten from 'lodash.flatten';
import get from 'lodash.get';
import groupBy from 'lodash.groupby';

import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import { settingsSet } from '../store/settings_slice';

import PicklistProductFormModal from './picklist_form/picklist_product_form_modal';
import PicklistProductList from './picklist_form/picklist_product_list';
import PicklistProductFieldArray from './picklist_form/picklist_product_field_array';

import LinkContainer from '../components/link_container';
import { renderOverlay, renderError, renderOffline } from '../components/render_helpers';
import SubmitButtonSpinner from '../components/submit_button_spinner';
import Field from '../components/form/rff_field';
import InputField from '../components/form/input_field';
import OnChangeField from '../components/form/rff_on_change_field';
import { coerceInput, pickValues, handleSubmitError } from '../lib/utils';
import {
  picklistFormPageQuery,
  picklistCreate as picklistCreateMutation,
  picklistUpdate as picklistUpdateMutation,
} from '../graphql/picklist_queries';
import * as updateFunctions from '../update_functions';
import {
  picklistDefaultValues,
  picklistProductDefaultValues,
  picklistSupplierCatalogItemDefaultValues,
} from '../defaults';
import { picklistFormValidator } from '../validators';
import {
  picklistWhiteList,
  picklistProductWhiteList,
  picklistSupplierCatalogItemWhiteList,
} from '../white_lists';

const PicklistForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [showPicklistProductFormModal, setShowPicklistProductFormModal] = useState(false);
  const [selectedPicklistProduct, setSelectedPicklistProduct] = useState({});
  const currentUser = useSelector((state) => state.auth.user);
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const [picklistCreate] = useMutation(picklistCreateMutation);
  const [picklistUpdate] = useMutation(picklistUpdateMutation);
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(picklistFormPageQuery, {
    variables: {
      hasPicklistId: !!params.id,
      picklistId: params.id || 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const initialValues = useMemo(() => {
    if (pageData?.picklist) {
      const values = pickValues(pageData.picklist, picklistWhiteList);
      values.picklistProducts = values.picklistProducts.map((pp) =>
        pickValues(pp, picklistProductWhiteList)
      );
      values.picklistSupplierCatalogItems = values.picklistSupplierCatalogItems.map(
        (psci) => pickValues(psci, picklistSupplierCatalogItemWhiteList)
      );
      return values;
    }
    return picklistDefaultValues;
  }, [pageData]);

  const perms = useMemo(() => {
    if (currentUser?.id) {
      return currentUser.perms;
    }
    return {};
  }, [currentUser]);

  const selectableProducts = useMemo(() => {
    if (params.id) {
      // TODO does this need thinning?
      return get(pageData, 'productList', []).filter((p) => p.id);
    }
    return [];
  }, [pageData, params]);

  const handlePicklistProductEditClicked = useCallback(
    (picklistProductId) => {
      const picklistProducts = get(pageData, 'picklist.picklistProducts', []);
      const picklistProduct = picklistProducts.find(
        (sci) => sci.id === parseInt(picklistProductId, 10)
      );
      if (picklistProduct) {
        setSelectedPicklistProduct(picklistProduct);
        setShowPicklistProductFormModal(true);
      }
    },
    [pageData]
  );

  const onCancel = () => {
    navigate('/picklists');
  };

  const onFormSubmit = async (data) => {
    const uuid = uuidv4();
    const submitData = cloneDeep(data);
    let mutation;
    let mutationMessageAction;
    const input = coerceInput(submitData);
    const mutationData = {
      variables: { input },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'PicklistType',
        recordId: params.id || uuid,
      },
    };
    if (params.id) {
      mutation = picklistUpdate;
      mutationMessageAction = 'update';
      mutationData.variables.id = params.id;
      mutationData.context.mutationType = 'UPDATE';
      mutationData.update = updateFunctions.picklistUpdate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'picklistUpdate',
        mutationData,
        currentData: pageData.picklist,
      });
    } else {
      mutation = picklistCreate;
      mutationData.context.mutationType = 'CREATE';
      mutationMessageAction = 'create';
      mutationData.update = updateFunctions.picklistCreate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'picklistCreate',
        mutationData,
      });
    }
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await mutation(mutationData);
        toastSuccess(`Picklist ${mutationMessageAction} succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        navigate('/picklists');
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      mutation(mutationData);
      toastWarning(
        `Picklist ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      navigate('/picklists');
    }
    return undefined;
  };

  const handlePicklistProductFormModalClick = () => {
    setShowPicklistProductFormModal(true);
  };

  const handlePicklistProductFormModalCancel = () => {
    setShowPicklistProductFormModal(false);
    setSelectedPicklistProduct({});
  };

  const handlePicklistProductFormModalComplete = () => {
    pageRefetch();
    setShowPicklistProductFormModal(false);
    setSelectedPicklistProduct({});
  };

  const calculatePicklistSupplierCatalogItems = (data) => {
    const { picklistProducts } = data;
    const rawItems = compact(
      flatten(
        picklistProducts.map((ptp) => {
          const { productId, quantity: picklistProductQuantity } = ptp;
          const product = get(pageData, 'productList', []).find(
            (p) => p.id === productId
          );
          // TODO the assemblies and recurse off the product.
          // Issue is the iteration how far down into the assemblied
          const productSupplierCatalogItems = get(
            product,
            'productSupplierCatalogItems',
            []
          );
          return productSupplierCatalogItems.map((psci) => {
            const {
              quantity: productSupplierCatalogItemQuantity,
              supplierCatalogItemId,
              supplierCatalogItem,
            } = psci;
            const {
              modelPartNumber,
              description,
              catalogItemUnitOfMeasure,
              catalogItemQuantityPerUnitOfMeasure,
            } = supplierCatalogItem;
            const payload = {
              ...picklistSupplierCatalogItemDefaultValues,
              quantity:
                defaultTo(parseInt(picklistProductQuantity, 10), 0) *
                defaultTo(parseInt(productSupplierCatalogItemQuantity, 10), 0),
              supplierCatalogItemId,
              modelPartNumber,
              description,
              catalogItemUnitOfMeasure,
              catalogItemQuantityPerUnitOfMeasure,
            };
            return payload;
          });
        })
      )
    );
    const groupedItems = groupBy(rawItems, 'supplierCatalogItemId');

    const picklistSupplierCatalogItems = Object.keys(groupedItems).map(
      (supplierCatalogItemId) => {
        const items = groupedItems[supplierCatalogItemId];
        const payload = {
          ...items[0],
          quantity: items.reduce((accum, item) => accum + item.quantity, 0),
        };
        payload.quantityUnitOfMeasure = Math.floor(
          payload.quantity / payload.catalogItemQuantityPerUnitOfMeasure
        );
        payload.quantityRemainder =
          payload.quantity -
          payload.quantityUnitOfMeasure * payload.catalogItemQuantityPerUnitOfMeasure;
        return payload;
      }
    );
    return picklistSupplierCatalogItems;
  };

  const onPicklistProductsChange = (form, values) => {
    const picklistSupplierCatalogItems = calculatePicklistSupplierCatalogItems(values);
    form.change('picklistSupplierCatalogItems', picklistSupplierCatalogItems);
  };

  const onPicklistTemplateIdChange = (form, values) => {
    let picklistProducts = [];
    const picklistTemplateId = defaultTo(parseInt(values.picklistTemplateId, 10), null);
    if (picklistTemplateId) {
      const picklistTemplate = get(pageData, 'picklistTemplateList', []).find(
        (pt) => pt.id === picklistTemplateId
      );
      if (picklistTemplate) {
        const picklistTemplateProducts = get(
          picklistTemplate,
          'picklistTemplateProducts',
          []
        );
        picklistProducts = picklistTemplateProducts.map((ptp) => {
          const newPicklistProduct = {
            ...picklistProductDefaultValues,
            ...(params.id && { picklistId: params.id }),
            productId: ptp.productId,
          };
          return newPicklistProduct;
        });
      }
    }
    form.change('picklistProducts', picklistProducts);
  };

  const renderContent = () => (
    <>
      {perms.developer && params.id && (
        <PicklistProductFormModal
          show={showPicklistProductFormModal}
          products={selectableProducts}
          picklistId={get(pageData, ['picklist', 'id'])}
          picklistName={get(pageData, ['picklist', 'name'])}
          onCancel={handlePicklistProductFormModalCancel}
          onComplete={handlePicklistProductFormModalComplete}
          settingsTenant={settingsTenant}
          settingsOnline={settingsOnline}
          picklistProduct={selectedPicklistProduct}
        />
      )}
      <Row className="mt-4 mb-3">
        <Col sm={12}>
          <div className="float-none">
            <div className="float-start">
              <h1 className="h3 mb-3">{params.id ? 'Edit Picklist' : 'New Picklist'}</h1>
            </div>
            <div className="float-end">
              <LinkContainer to="/picklists">
                <Button className="me-2" variant="primary">
                  All Picklists
                </Button>
              </LinkContainer>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <FinalForm
            initialValues={initialValues}
            onSubmit={(data) => onFormSubmit(data)}
            validate={picklistFormValidator}
            mutators={{ setFieldTouched, ...arrayMutators }}
          >
            {({ handleSubmit, pristine, submitting, form, values }) => (
              <form noValidate>
                <Card>
                  <Card.Body>
                    <fieldset className="border rounded-3 p-3">
                      <legend className="float-none w-auto px-3 fs-6">
                        Picklist Detail
                      </legend>
                      <OnChangeField name="picklistTemplateId">
                        {() => onPicklistTemplateIdChange(form, values)}
                      </OnChangeField>
                      <OnChangeField name="picklistProducts">
                        {() => onPicklistProductsChange(form, values)}
                      </OnChangeField>
                      <Field
                        name="picklistTemplateId"
                        component={InputField}
                        asElement="select"
                        selectOptions={get(pageData, 'picklistTemplateList', [])}
                        defaultSelectOptionText="select picklist template..."
                        labelWidth={4}
                        inputWidth={4}
                        size="lg"
                      >
                        Picklist Template
                      </Field>
                      <Field
                        name="fromSiteLocationId"
                        component={InputField}
                        asElement="select"
                        selectOptions={get(pageData, 'siteLocationList', [])}
                        defaultSelectOptionText="select from location..."
                        labelWidth={4}
                        inputWidth={4}
                        size="lg"
                      >
                        Pick From Location
                      </Field>
                      <Field
                        name="toSiteLocationId"
                        component={InputField}
                        asElement="select"
                        selectOptions={get(pageData, 'siteLocationList', [])}
                        defaultSelectOptionText="select to location..."
                        labelWidth={4}
                        inputWidth={4}
                        size="lg"
                      >
                        Pick To Location
                      </Field>
                      <Field
                        name="picklistNotes"
                        component={InputField}
                        labelWidth={4}
                        inputWidth={8}
                        size="lg"
                        asElement="textarea"
                        rows={2}
                      >
                        Pick Notes
                      </Field>
                    </fieldset>
                    <fieldset className="border rounded-3 p-3 mt-3">
                      <legend className="float-none w-auto px-3 fs-6">
                        Product Assemblies
                      </legend>
                      <PicklistProductFieldArray
                        picklist={pageData.picklist}
                        products={get(pageData, 'productList', [])}
                      />
                    </fieldset>
                    <Form.Group as={Row}>
                      <Col sm={12}>
                        <ButtonToolbar
                          className="mt-3"
                          style={{ justifyContent: 'flex-end' }}
                        >
                          <ButtonGroup className="me-2">
                            <Button
                              variant="danger"
                              onClick={onCancel}
                              disabled={submitting}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="button"
                              variant="primary"
                              disabled={pristine || submitting}
                              onClick={handleSubmit}
                            >
                              {submitting && <SubmitButtonSpinner />}
                              {params.id ? 'Update' : 'Create'}
                            </Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                      </Col>
                    </Form.Group>
                  </Card.Body>
                </Card>
              </form>
            )}
          </FinalForm>
        </Col>
      </Row>
      {perms.developer && (
        <Row className="mt-4">
          <Col sm={12}>
            <Card>
              <Card.Header>Picklist Product Assemblies</Card.Header>
              <Card.Body>
                {params.id ? (
                  <>
                    <PicklistProductList
                      picklistProducts={get(
                        pageData,
                        ['picklist', 'picklistProducts'],
                        []
                      )}
                      currentUser={currentUser}
                      settingsTenant={settingsTenant}
                      settingsOnline={settingsOnline}
                      onEdit={handlePicklistProductEditClicked}
                      onDelete={() => pageRefetch()}
                    />
                    <Button
                      className="me-2"
                      type="button"
                      variant="primary"
                      onClick={handlePicklistProductFormModalClick}
                    >
                      Add Picklist Assembly
                    </Button>
                  </>
                ) : (
                  <p>Create a new Picklist to begin adding Product Assemblies</p>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default PicklistForm;

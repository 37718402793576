import { Suspense } from 'react';
import { Outlet } from 'react-router';

import LoaderComponent from '../components/loader_component';

const AuthLayout = () => (
  <div
    style={{
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <div style={{ width: '600px' }}>
      <Suspense fallback={<LoaderComponent />}>
        <Outlet />
      </Suspense>
    </div>
  </div>
);

export default AuthLayout;

import { useMemo } from 'react';
import {
  Button,
  Col,
  Row,
  Card,
  ButtonToolbar,
  ButtonGroup,
  Form,
} from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useNavigate, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import { v4 as uuidv4 } from 'uuid';

import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';

import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import { settingsSet } from '../store/settings_slice';
import LinkContainer from '../components/link_container';
import { renderOverlay, renderError, renderOffline } from '../components/render_helpers';
import SubmitButtonSpinner from '../components/submit_button_spinner';
import Field from '../components/form/rff_field';
import InputField from '../components/form/input_field';
import { coerceInput, pickValues, handleSubmitError } from '../lib/utils';
import {
  wbsCodeFormPageQuery,
  wbsCodeCreate as wbsCodeCreateMutation,
  wbsCodeUpdate as wbsCodeUpdateMutation,
} from '../graphql/wbs_code_queries';
import * as updateFunctions from '../update_functions';
import { wbsCodeDefaultValues } from '../defaults';
import { wbsCodeFormValidator } from '../validators';
import { wbsCodeWhiteList } from '../white_lists';

const WbsCodeForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const [wbsCodeCreate] = useMutation(wbsCodeCreateMutation);
  const [wbsCodeUpdate] = useMutation(wbsCodeUpdateMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    networkStatus: pageNetworkStatus,
  } = useQuery(wbsCodeFormPageQuery, {
    variables: {
      hasWbsCodeId: !!params.id,
      wbsCodeId: params.id || 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const initialValues = useMemo(() => {
    if (pageData?.wbsCode) {
      const values = pickValues(pageData.wbsCode, wbsCodeWhiteList);
      return values;
    }
    return wbsCodeDefaultValues;
  }, [pageData]);

  const parentOptions = useMemo(() => {
    if (pageData?.wbsCodeList) {
      if (params.id) {
        return pageData.wbsCodeList.filter((wc) => wc.id !== parseInt(params.id, 10));
      }
      return get(pageData, 'wbsCodeList', []);
    }
    return [];
  }, [pageData, params]);

  const onCancel = () => {
    navigate('/wbs_codes');
  };

  const onFormSubmit = async (data) => {
    const uuid = uuidv4();
    const submitData = cloneDeep(data);
    let mutation;
    let mutationMessageAction;
    const input = coerceInput(submitData);
    const mutationData = {
      variables: { input },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'WbsCodeType',
        recordId: params.id || uuid,
      },
    };

    if (params.id) {
      mutation = wbsCodeUpdate;
      mutationMessageAction = 'update';
      mutationData.variables.id = params.id;
      mutationData.context.mutationType = 'UPDATE';
      mutationData.update = updateFunctions.wbsCodeUpdate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'wbsCodeUpdate',
        mutationData,
        currentData: pageData.wbsCode,
      });
    } else {
      mutation = wbsCodeCreate;
      mutationData.context.mutationType = 'CREATE';
      mutationMessageAction = 'create';
      mutationData.update = updateFunctions.wbsCodeCreate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'wbsCodeCreate',
        mutationData,
      });
    }

    dispatch(settingsSet({ mutating: true }));

    if (settingsOnline) {
      try {
        await mutation(mutationData);
        toastSuccess(`WbsCode ${mutationMessageAction} succeeded`);
        dispatch(settingsSet({ mutating: false }));
        navigate('/wbs_codes');
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(settingsSet({ mutating: false }));
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      mutation(mutationData);
      toastWarning(
        `WbsCode ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(settingsSet({ mutating: false }));
      navigate('/wbs_codes');
    }
    return undefined;
  };

  const renderLevel = () => {
    if (pageData?.wbsCode) {
      if (get(pageData, 'wbsCode.parent.parent')) {
        return 'Category - Task - *Activity*';
      }
      if (get(pageData, 'wbsCode.parent')) {
        return 'Category - *Task* - Activity';
      }
      return '*Category* - Task - Activity';
    }
    return '-';
  };
  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm={12}>
          <div className="float-none">
            <div className="float-start">
              <h1 className="h3 mb-3">{params.id ? 'Edit Wbs Code' : 'New Wbs Code'}</h1>
            </div>
            <div className="float-end">
              <LinkContainer to="/wbs_codes">
                <Button className="me-2" variant="primary">
                  All Wbs Codes
                </Button>
              </LinkContainer>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <FinalForm
            initialValues={initialValues}
            onSubmit={onFormSubmit}
            validate={wbsCodeFormValidator}
            mutators={{ setFieldTouched }}
          >
            {({ handleSubmit, pristine, submitting }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Card>
                  <Card.Body>
                    <InputField
                      input={{
                        name: 'level',
                        value: renderLevel(),
                      }}
                      meta={{}}
                      plainText
                      helpBlock={false}
                      type="text"
                      labelWidth={4}
                      inputWidth={8}
                      noTab
                      size="lg"
                    >
                      Level
                    </InputField>
                    <Field
                      name="prefix"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={8}
                      size="lg"
                    >
                      Prefix
                    </Field>
                    <Field
                      name="code"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={8}
                      size="lg"
                    >
                      Code
                    </Field>
                    <Field
                      name="name"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={8}
                      size="lg"
                    >
                      Name
                    </Field>
                    <Field
                      name="parentId"
                      component={InputField}
                      asElement="select"
                      selectOptions={parentOptions}
                      defaultSelectOptionText="select optional parent..."
                      labelWidth={4}
                      inputWidth={4}
                      size="lg"
                    >
                      Parent
                    </Field>
                    <Form.Group as={Row}>
                      <Col sm={12}>
                        <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                          <ButtonGroup className="me-2">
                            <Button
                              variant="danger"
                              onClick={onCancel}
                              disabled={submitting}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              variant="primary"
                              disabled={pristine || submitting}
                            >
                              {submitting && <SubmitButtonSpinner />}
                              {params.id ? 'Update' : 'Create'}
                            </Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                      </Col>
                    </Form.Group>
                  </Card.Body>
                </Card>
              </form>
            )}
          </FinalForm>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default WbsCodeForm;

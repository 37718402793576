import { useMemo, useState } from 'react';
import { Button, Col, Row, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { useQuery, NetworkStatus } from '@apollo/client';
import { useNavigate, useParams } from 'react-router';

import get from 'lodash.get';

import {
  renderOverlay,
  renderError,
  renderOffline,
  renderDateString,
} from '../components/render_helpers';
import { consignmentShowPageQuery } from '../graphql/consignment_queries';

import DlHorizontal from '../components/dl_horizontal';
import ConsignmentItemList from './consignment_show/consignment_item_list';
import ConsignmentImageList from './consignment_show/consignment_image_list';
import ConsignmentItemFormModal from './consignment_show/consignment_item_form_modal';
import ConsignmentImageFormModal from './consignment_show/consignment_image_form_modal';
import ConsignmentImageCaptureModal from './consignment_show/consignment_image_capture_modal';

const ConsignmentShow = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [showConsignmentItemFormModal, setShowConsignmentItemFormModal] = useState(false);
  const [showConsignmentImageFormModal, setShowConsignmentImageFormModal] =
    useState(false);
  const [showConsignmentImageCaptureModal, setShowConsignmentImageCaptureModal] =
    useState(false);
  const currentUser = useSelector((state) => state.auth.user);
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(consignmentShowPageQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { consignmentId: params.id },
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const siteLocations = useMemo(() => {
    if (pageData?.consignment) {
      const consignmentSiteId = get(pageData, 'consignment.siteId');
      return pageData.siteLocationList.filter((sl) => sl.siteId === consignmentSiteId);
    }
    return [];
  }, [pageData]);

  const handleConsignmentItemFormModalClick = () => {
    setShowConsignmentItemFormModal(true);
  };

  const handleConsignmentItemFormModalCancel = () => {
    setShowConsignmentItemFormModal(false);
  };

  const handleConsignmentItemFormModalComplete = () => {
    pageRefetch();
    setShowConsignmentItemFormModal(false);
  };

  const handleConsignmentImageFormModalClick = () => {
    setShowConsignmentImageFormModal(true);
  };

  const handleConsignmentImageFormModalCancel = () => {
    setShowConsignmentImageFormModal(false);
  };

  const handleConsignmentImageFormModalComplete = () => {
    pageRefetch();
    setShowConsignmentImageFormModal(false);
  };

  const handleConsignmentImageCaptureModalClick = () => {
    setShowConsignmentImageCaptureModal(true);
  };

  const handleConsignmentImageCaptureModalCancel = () => {
    setShowConsignmentImageCaptureModal(false);
  };

  const handleConsignmentImageCaptureModalComplete = () => {
    pageRefetch();
    setShowConsignmentImageCaptureModal(false);
  };

  const renderContent = () => {
    const {
      enums,
      // consignment,
      consignment: {
        id: consignmentId,
        site: { name: siteName },
        supplier: { name: supplierName },
        name,
        containerIdentifier,
        shipmentNumber,
        containerReference,
        containerNumber,
        containerSealNumber,
        estimatedDeliveryAt,
        consignmentItems,
        consignmentImages,
      },
    } = pageData;
    const consignmentName = `${supplierName}-${name}`;

    return (
      <>
        <ConsignmentItemFormModal
          show={showConsignmentItemFormModal}
          enums={enums}
          consignmentId={consignmentId}
          consignmentName={consignmentName}
          onCancel={handleConsignmentItemFormModalCancel}
          onComplete={handleConsignmentItemFormModalComplete}
          settingsTenant={settingsTenant}
          settingsOnline={settingsOnline}
          siteLocations={siteLocations}
        />
        <ConsignmentImageCaptureModal
          show={showConsignmentImageCaptureModal}
          consignmentId={consignmentId}
          consignmentName={consignmentName}
          onCancel={handleConsignmentImageCaptureModalCancel}
          onComplete={handleConsignmentImageCaptureModalComplete}
          settingsTenant={settingsTenant}
          settingsOnline={settingsOnline}
        />
        <ConsignmentImageFormModal
          show={showConsignmentImageFormModal}
          consignmentId={consignmentId}
          consignmentName={consignmentName}
          onCancel={handleConsignmentImageFormModalCancel}
          onComplete={handleConsignmentImageFormModalComplete}
          settingsTenant={settingsTenant}
          settingsOnline={settingsOnline}
        />
        <Row className="mt-4 mb-3">
          <Col sm={12}>
            <div className="float-none">
              <div className="float-start">
                <h1 className="h3 mb-3">{`Consignment Details for ${consignmentName}`}</h1>
              </div>
              <div className="float-end">
                <Button className="me-2" variant="primary" onClick={() => navigate(-1)}>
                  Consignments
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <hr />
        </Row>
        <Row>
          <Col sm={3}>
            <Card>
              <Card.Header>Details</Card.Header>
              <Card.Body>
                <DlHorizontal dt="ID" dd={consignmentId} />
                <DlHorizontal dt="Site" dd={siteName} />
                <DlHorizontal dt="Supplier" dd={supplierName} />
                <DlHorizontal dt="Container Identifier" dd={containerIdentifier} />
                <DlHorizontal dt="Shipment Number" dd={shipmentNumber} />
                <DlHorizontal dt="Container Reference" dd={containerReference} />
                <DlHorizontal dt="Container Number" dd={containerNumber} />
                <DlHorizontal dt="Container Seal Number" dd={containerSealNumber} />
                <DlHorizontal
                  dt="Estimated Delivery"
                  dd={renderDateString(estimatedDeliveryAt)}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col sm={9}>
            <Row>
              <Col sm={12}>
                <Card>
                  <Card.Header>Consignment Actions</Card.Header>
                  <Card.Body>
                    <Button
                      className="me-2"
                      type="button"
                      variant="primary"
                      onClick={handleConsignmentItemFormModalClick}
                    >
                      Add Item
                    </Button>
                    <Button
                      className="me-2"
                      type="button"
                      variant="primary"
                      onClick={handleConsignmentImageFormModalClick}
                    >
                      Upload Image
                    </Button>
                    <Button
                      className="me-2"
                      type="button"
                      variant="primary"
                      onClick={handleConsignmentImageCaptureModalClick}
                    >
                      Capture Image
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col sm={12}>
                <Card>
                  <Card.Header>Consignment Items</Card.Header>
                  <Card.Body>
                    <ConsignmentItemList
                      consignmentItems={consignmentItems}
                      currentUser={currentUser}
                      settingsTenant={settingsTenant}
                      settingsOnline={settingsOnline}
                      onDelete={() => pageRefetch()}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col sm={12}>
                <Card>
                  <Card.Header>Consignment Images</Card.Header>
                  <Card.Body>
                    <ConsignmentImageList
                      consignmentImages={consignmentImages}
                      currentUser={currentUser}
                      settingsTenant={settingsTenant}
                      settingsOnline={settingsOnline}
                      onDelete={() => pageRefetch()}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default ConsignmentShow;

import { useMemo } from 'react';
import {
  Button,
  Col,
  Row,
  Card,
  ButtonToolbar,
  ButtonGroup,
  Form,
} from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useNavigate, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import { v4 as uuidv4 } from 'uuid';
import proj4 from 'proj4';

import cloneDeep from 'lodash.clonedeep';
import defaultTo from 'lodash.defaultto';
import get from 'lodash.get';

import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import { settingsSet } from '../store/settings_slice';

import LinkContainer from '../components/link_container';
import { renderOverlay, renderError, renderOffline } from '../components/render_helpers';
import SubmitButtonSpinner from '../components/submit_button_spinner';
import Field from '../components/form/rff_field';
import ReactDateTimeField from '../components/form/react_date_time_field';
import InputField from '../components/form/input_field';
import OnChangeField from '../components/form/rff_on_change_field';
import { coerceInput, pickValues, handleSubmitError } from '../lib/utils';
import {
  tableFormPageQuery,
  tableCreate as tableCreateMutation,
  tableUpdate as tableUpdateMutation,
} from '../graphql/table_queries';
import * as updateFunctions from '../update_functions';
import { tableDefaultValues } from '../defaults';
import { tableFormValidator } from '../validators';
import { tableWhiteList } from '../white_lists';

const TableForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const [tableCreate] = useMutation(tableCreateMutation);
  const [tableUpdate] = useMutation(tableUpdateMutation);
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    networkStatus: pageNetworkStatus,
  } = useQuery(tableFormPageQuery, {
    variables: {
      hasTableId: !!params.id,
      tableId: params.id || 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const initialValues = useMemo(() => {
    if (pageData?.table) {
      const values = pickValues(pageData.table, tableWhiteList);
      return values;
    }
    return tableDefaultValues;
  }, [pageData]);

  const onCancel = () => {
    navigate('/tables');
  };

  const onFormSubmit = async (data) => {
    const uuid = uuidv4();
    const submitData = cloneDeep(data);
    let mutation;
    let mutationMessageAction;
    const input = coerceInput(submitData);
    const mutationData = {
      variables: { input },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'TableType',
        recordId: params.id || uuid,
      },
    };
    if (params.id) {
      mutation = tableUpdate;
      mutationMessageAction = 'update';
      mutationData.variables.id = params.id;
      mutationData.context.mutationType = 'UPDATE';
      mutationData.update = updateFunctions.tableUpdate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'tableUpdate',
        mutationData,
        currentData: pageData.table,
      });
    } else {
      mutation = tableCreate;
      mutationData.context.mutationType = 'CREATE';
      mutationMessageAction = 'create';
      mutationData.update = updateFunctions.tableCreate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'tableCreate',
        mutationData,
      });
    }
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await mutation(mutationData);
        toastSuccess(`Table ${mutationMessageAction} succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        navigate('/tables');
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      mutation(mutationData);
      toastWarning(
        `Table ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      navigate('/tables');
    }
    return undefined;
  };

  const round9 = (num) => Math.round(num * 1000000000) / 1000000000;

  const onEpsg2105Change = (form, values) => {
    let lat;
    let lng;
    let geom;
    const epsg2105Northing = defaultTo(parseFloat(values.epsg2105Northing), null);
    const epsg2105Easting = defaultTo(parseFloat(values.epsg2105Easting), null);
    if (epsg2105Northing && epsg2105Easting) {
      const transformNW = proj4('EPSG:2105', 'EPSG:4326', [
        epsg2105Easting,
        epsg2105Northing,
      ]);
      if (transformNW && transformNW.length === 2) {
        const [lngNW, latNW] = transformNW;
        lng = round9(lngNW);
        lat = round9(latNW);

        const [lngNE, latNE] = proj4('EPSG:2105', 'EPSG:4326', [
          epsg2105Easting + 18.7425,
          epsg2105Northing,
        ]);

        const [lngSE, latSE] = proj4('EPSG:2105', 'EPSG:4326', [
          epsg2105Easting + 18.7425,
          epsg2105Northing - 4.2,
        ]);

        const [lngSW, latSW] = proj4('EPSG:2105', 'EPSG:4326', [
          epsg2105Easting,
          epsg2105Northing - 4.2,
        ]);

        geom = {
          type: 'Polygon',
          coordinates: [
            [
              [round9(latNW), round9(lngNW)],
              [round9(latNE), round9(lngNE)],
              [round9(latSE), round9(lngSE)],
              [round9(latSW), round9(lngSW)],
              [round9(latNW), round9(lngNW)],
            ],
          ],
        };
      }
    }
    form.change('lat', lat);
    form.change('lng', lng);
    form.change('geom', geom);
  };

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm="auto">
          <h1 className="h3 mb-3">{params.id ? 'Edit Table' : 'New Table'}</h1>
        </Col>
        <Col>
          <Row className="justify-content-end g-0">
            <Col sm="auto">
              <LinkContainer to="/tables">
                <Button variant="primary">All Tables</Button>
              </LinkContainer>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <FinalForm
            initialValues={initialValues}
            onSubmit={(data) => onFormSubmit(data)}
            validate={tableFormValidator}
            mutators={{ setFieldTouched }}
          >
            {({ handleSubmit, pristine, submitting, form, values }) => (
              <form noValidate>
                <Card>
                  <Card.Body>
                    <OnChangeField name="epsg2105Northing">
                      {() => onEpsg2105Change(form, values)}
                    </OnChangeField>
                    <OnChangeField name="epsg2105Easting">
                      {() => onEpsg2105Change(form, values)}
                    </OnChangeField>
                    <Field
                      name="name"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={8}
                      size="md"
                    >
                      Name
                    </Field>
                    <Field
                      name="inverterId"
                      component={InputField}
                      asElement="select"
                      selectOptions={get(pageData, 'inverterList', [])}
                      defaultSelectOptionText="select inverter..."
                      labelWidth={4}
                      inputWidth={4}
                      size="md"
                    >
                      Inverter
                    </Field>
                    <Field
                      name="buildStatus"
                      component={InputField}
                      asElement="select"
                      selectOptions={Object.values(
                        get(pageData, 'enums.enums.TableBuildStatuses', [])
                      ).map((type) => ({
                        id: type,
                        name: type,
                      }))}
                      defaultSelectOptionText="select build status..."
                      labelWidth={4}
                      inputWidth={4}
                      size="md"
                    >
                      Build Status
                    </Field>
                    <Field
                      name="tableType"
                      component={InputField}
                      asElement="select"
                      selectOptions={Object.values(
                        get(pageData, 'enums.enums.TableTypes', [])
                      ).map((type) => ({
                        id: type,
                        name: type,
                      }))}
                      defaultSelectOptionText="select table type..."
                      labelWidth={4}
                      inputWidth={4}
                      size="md"
                    >
                      Table Type
                    </Field>
                    <Field
                      name="epsg2105Northing"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={4}
                      size="md"
                    >
                      epsg2105 Northing
                    </Field>
                    <Field
                      name="epsg2105Easting"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={4}
                      size="md"
                    >
                      epsg2105 Easting
                    </Field>
                    <Field
                      name="lat"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={4}
                      size="md"
                    >
                      Latitude
                    </Field>
                    <Field
                      name="lng"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={4}
                      size="md"
                    >
                      Longitude
                    </Field>
                    <Field
                      size="md"
                      name="finishedAt"
                      labelWidth={4}
                      inputWidth={4}
                      component={ReactDateTimeField}
                      helpText="YYYY-MM-DD HH:mm"
                      dateFormat="YYYY-MM-DD"
                      timeFormat="HH:mm"
                      closeOnSelect
                      // initialViewMode="time"
                    >
                      Finished At
                    </Field>
                    <Field
                      size="md"
                      name="finishedOn"
                      labelWidth={4}
                      inputWidth={4}
                      component={ReactDateTimeField}
                      helpText="YYYY-MM-DD"
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      closeOnSelect
                    >
                      Finished On
                    </Field>
                    <Form.Group as={Row}>
                      <Col sm={12}>
                        <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                          <ButtonGroup className="me-2">
                            <Button
                              variant="danger"
                              onClick={onCancel}
                              disabled={submitting}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="button"
                              variant="primary"
                              disabled={pristine || submitting}
                              onClick={handleSubmit}
                            >
                              {submitting && <SubmitButtonSpinner />}
                              {params.id ? 'Update' : 'Create'}
                            </Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                      </Col>
                    </Form.Group>
                  </Card.Body>
                </Card>
              </form>
            )}
          </FinalForm>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default TableForm;

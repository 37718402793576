import { useMemo, useCallback, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Row,
  Card,
  ButtonToolbar,
  ButtonGroup,
  Form,
  Tabs,
  Tab,
} from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import readXlsxFile, { readSheetNames } from 'read-excel-file';
import { Queue } from 'async-await-queue';
import { v4 as uuidv4 } from 'uuid';

import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';
import isEqual from 'lodash.isequal';
import uniqWith from 'lodash.uniqwith';

import { wbsCodeSchema } from '../lib/importers/wbs_code_schema';

import { renderOverlay, renderError, renderOffline } from '../components/render_helpers';
import SubmitButtonSpinner from '../components/submit_button_spinner';
import Field from '../components/form/rff_field';
import InputField from '../components/form/input_field';

import { wbsCodeImportFormPageQuery } from '../graphql/import_queries';
import {
  wbsCodeCreate as wbsCodeCreateMutation,
  // wbsCodeUpdate as wbsCodeUpdateMutation,
} from '../graphql/wbs_code_queries';
import * as updateFunctions from '../update_functions';

const wbsCodeQueue = new Queue(1, 25);
const priority = -1;

const WbsCodeImportForm = () => {
  // this isn't needed probably
  const projectCode = '000466';
  const navigate = useNavigate();
  const params = useParams();
  const [fileError, setFileError] = useState('');
  const [fileRows, setFileRows] = useState([]);
  const [fileErrors, setFileErrors] = useState([]);
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const [wbsCodeCreate] = useMutation(wbsCodeCreateMutation);
  // const [wbsCodeUpdate] = useMutation(wbsCodeUpdateMutation);
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    networkStatus: pageNetworkStatus,
    refetch: pageRefetch,
  } = useQuery(wbsCodeImportFormPageQuery, {
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const handleFileChange = useCallback(async (name, onChange, e) => {
    const file = get(e, 'target.files.0');
    if (file) {
      setFileError('');
      const sheetNames = await readSheetNames(file);
      try {
        if (sheetNames.includes('V2.0')) {
          const { rows, errors = [] } = await readXlsxFile(file, {
            schema: wbsCodeSchema,
            sheet: 'V2.0',
          });
          const newFileRows = rows.map((row) => {
            const newRow = cloneDeep(row);
            // const rowIndex = index + 2;
            return newRow;
          });
          setFileError('');
          setFileRows(newFileRows);
          setFileErrors(errors);
          onChange(file);
        } else {
          setFileError('Spreadsheet must include a sheet titled "V2.0"');
          setFileRows([]);
          setFileErrors([]);
        }
      } catch (err) {
        setFileError(err.message);
        setFileRows([]);
        setFileErrors([]);
      }
    }
  }, []);

  const onCancel = () => {
    navigate('/');
  };

  const onFormSubmit = async () => {
    const currentGrandParents = get(pageData, 'wbsCodeList').filter((c) => !c.parentId);
    // console.log({ currentGrandParents });
    const rowGrandParents = uniqWith(
      fileRows.map(({ prefix, categoryCode, categoryName }) => ({
        prefix,
        code: categoryCode,
        name: categoryName,
      })),
      isEqual
    );
    // console.log({ rowGrandParents });
    const persistableGrandParents = rowGrandParents.filter(({ prefix, code, name }) => {
      const currentGrantParent = currentGrandParents.find(
        (cgp) => cgp.prefix === prefix && cgp.code === code && cgp.name === name
      );
      if (currentGrantParent) {
        return false;
      }
      return true;
    });
    // console.log({ persistableGrandParents });

    const grandParentPromises = persistableGrandParents.map(
      async (persistableGrandParent, index) => {
        await wbsCodeQueue.wait(index, priority);
        const uuid = uuidv4();
        const mutationData = {
          variables: {
            input: {
              ...persistableGrandParent,
            },
          },
          context: {
            serializationKey: settingsTenant,
            tracked: true,
            recordType: 'WbsCodeType',
            recordId: uuid,
            mutationType: 'CREATE',
          },
        };
        mutationData.update = updateFunctions.wbsCodeCreate;
        mutationData.optimisticResponse = updateFunctions.optimisticNew({
          mutationName: 'wbsCodeCreate',
          mutationData,
        });
        try {
          const resp = await wbsCodeCreate(mutationData);
          return get(resp, 'data.wbsCodeCreate');
        } finally {
          wbsCodeQueue.end(index);
        }
      }
    );
    const grandParentResponses = await Promise.all(grandParentPromises);
    const grandParents = [...currentGrandParents, ...grandParentResponses];
    // console.log({ grandParents });

    const currentParents = get(pageData, 'wbsCodeList').filter(
      (c) => c.parent && !c.parent.parent
    );
    // console.log({ currentParents });
    const rowParents = uniqWith(
      fileRows.map(({ prefix, categoryCode, categoryName, taskCode, taskName }) => ({
        prefix,
        categoryCode,
        categoryName,
        code: taskCode,
        name: taskName,
      })),
      isEqual
    );
    // console.log({ rowParents });
    const persistableParents = rowParents.filter(({ prefix, code, name }) => {
      const currentParent = currentParents.find(
        (cp) => cp.prefix === prefix && cp.code === code && cp.name === name
      );
      if (currentParent) {
        return false;
      }
      return true;
    });
    // console.log({ persistableParents });

    const parentPromises = persistableParents.map(async (persistableParent, index) => {
      await wbsCodeQueue.wait(index, priority);
      const { prefix, categoryCode, categoryName, code, name } = persistableParent;
      const parent = grandParents.find(
        (gp) =>
          gp.prefix === prefix && gp.code === categoryCode && gp.name === categoryName
      );
      if (!parent) {
        throw new Error('Expected parent not found for task');
      }
      const uuid = uuidv4();
      const mutationData = {
        variables: {
          input: {
            prefix,
            code,
            name,
            parentId: parent.id,
          },
        },
        context: {
          serializationKey: settingsTenant,
          tracked: true,
          recordType: 'WbsCodeType',
          recordId: uuid,
          mutationType: 'CREATE',
        },
      };
      mutationData.update = updateFunctions.wbsCodeCreate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'wbsCodeCreate',
        mutationData,
      });
      try {
        const resp = await wbsCodeCreate(mutationData);
        return get(resp, 'data.wbsCodeCreate');
      } finally {
        wbsCodeQueue.end(index);
      }
    });
    const parentResponses = await Promise.all(parentPromises);
    const parents = [...currentParents, ...parentResponses];
    // console.log({ parents });

    const currentChildren = get(pageData, 'wbsCodeList').filter(
      (c) => c.parent && c.parent.parent
    );
    // console.log({ currentChildren });
    const rowChildren = uniqWith(
      fileRows.map(
        ({
          prefix,
          categoryCode,
          categoryName,
          taskCode,
          taskName,
          activityCode,
          activityName,
        }) => ({
          prefix,
          categoryCode,
          categoryName,
          taskCode,
          taskName,
          code: activityCode,
          name: activityName,
        })
      ),
      isEqual
    );
    // console.log({ rowChildren });
    const persistableChildren = rowChildren.filter(({ prefix, code, name }) => {
      const currentParent = currentChildren.find(
        (cc) => cc.prefix === prefix && cc.code === code && cc.name === name
      );
      if (currentParent) {
        return false;
      }
      return true;
    });
    // console.log({ persistableChildren });

    const childrenPromises = persistableChildren.map(async (persistableChild, index) => {
      await wbsCodeQueue.wait(index, priority);
      const { prefix, taskCode, taskName, code, name } = persistableChild;
      const parent = parents.find(
        (p) => p.prefix === prefix && p.code === taskCode && p.name === taskName
      );
      if (!parent) {
        throw new Error('Expected parent not found for activity');
      }
      const uuid = uuidv4();
      const mutationData = {
        variables: {
          input: {
            prefix,
            code,
            name,
            parentId: parent.id,
          },
        },
        context: {
          serializationKey: settingsTenant,
          tracked: true,
          recordType: 'WbsCodeType',
          recordId: uuid,
          mutationType: 'CREATE',
        },
      };
      mutationData.update = updateFunctions.wbsCodeCreate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'wbsCodeCreate',
        mutationData,
      });
      try {
        const resp = await wbsCodeCreate(mutationData);
        return get(resp, 'data.wbsCodeCreate');
      } finally {
        wbsCodeQueue.end(index);
      }
    });
    const childrenResponses = await Promise.all(childrenPromises);
    // const children = [...currentChildren, ...childrenResponses];
    // console.log({ children });
  };

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm={12}>
          <div className="float-none">
            <div className="float-start">
              <h1 className="h3 mb-3">WBS Code Import</h1>
              <p>This form is to upsert wbs codes</p>
            </div>
          </div>
        </Col>
      </Row>
      {fileError && (
        <Row>
          <Col>
            <Alert variant="danger">{fileError}</Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <FinalForm
            onSubmit={(data) => onFormSubmit(data)}
            mutators={{ setFieldTouched }}
          >
            {({ handleSubmit, pristine, submitting }) => (
              <form noValidate>
                <Card>
                  <Card.Body>
                    <Field
                      type="file"
                      name="file"
                      labelWidth={4}
                      inputWidth={4}
                      size="lg"
                      component={InputField}
                      customOnChange={handleFileChange}
                    />
                    <Form.Group as={Row}>
                      <Col sm={12}>
                        <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                          <ButtonGroup className="me-2">
                            <Button
                              variant="primary"
                              onClick={() => pageRefetch()}
                              disabled={!settingsOnline}
                            >
                              Refresh
                            </Button>
                            <Button
                              variant="danger"
                              onClick={onCancel}
                              disabled={submitting}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="button"
                              variant="primary"
                              disabled={pristine || submitting}
                              onClick={handleSubmit}
                            >
                              {submitting && <SubmitButtonSpinner />}
                              {params.id ? 'Update' : 'Create'}
                            </Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                      </Col>
                    </Form.Group>
                  </Card.Body>
                </Card>
              </form>
            )}
          </FinalForm>
        </Col>
      </Row>
      {projectCode && (
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <p>{`Project Code: ${projectCode}`}</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      {fileRows.length > 0 && (
        <Row>
          <Col>
            <Tabs defaultActiveKey="rows" id="uncontrolled-tab-example" className="mb-3">
              <Tab eventKey="rows" title="Rows">
                <pre>{JSON.stringify(fileRows, undefined, 2)}</pre>
              </Tab>
              <Tab eventKey="errors" title="Errors">
                <pre>{JSON.stringify(fileErrors, undefined, 2)}</pre>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default WbsCodeImportForm;

import { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import Chart from 'react-apexcharts';
import { DateTime, Interval } from 'luxon';
// import makeSigmoid from 'awesome-sigmoid';

import { sentenceCase } from 'change-case';
import clone from 'lodash.clone';
import get from 'lodash.get';
import defaultsDeep from 'lodash.defaultsdeep';

import defaultOptions from './default_datetime_options';
import makeSigmoid from '../../lib/math_utils';

const PilingByDayDateTimeChart = ({ pageData, TableBuildStatuses }) => {
  const chartData = useMemo(() => {
    if (pageData?.pileByDateByPileMachineReport) {
      const totalPileCount = 24710;
      const pileData = get(pageData, 'pileByDateByPileMachineReport.report.data');
      const sortedPileData = clone(pileData).sort((a, b) =>
        a.finishedOn.localeCompare(b.finishedOn)
      );
      const begin = DateTime.fromISO('2024-11-01T00:00:00', { zone: 'Pacific/Auckland' });
      const end = DateTime.fromISO('2025-04-01T00:00:00', { zone: 'Pacific/Auckland' });
      const interval = Interval.fromDateTimes(begin, end);
      const logDates = interval.splitBy({ days: 1 }).map((d) => d.start.toISODate());
      // console.log(logDates);
      const logDateLength = logDates.length;

      const mySigmoid = makeSigmoid({
        // center: 50,
        center: Math.floor((logDateLength / 10) * 5),
        deviation: 75,
        deviationOutput: 0.99,
      });
      // const xs = [...Array(logDateLength).keys()].map((i) => totalPiles * mySigmoid(i));
      // console.log(xs);
      const sigmoidData = logDates.map((date, idx) => ({
        x: new Date(date).getTime(),
        y: Math.floor(totalPileCount * mySigmoid(idx + 1)),
      }));
      let accumTotalPiles = 0;
      const actualData = sortedPileData.map(({ finishedOn, totalPiles }) => {
        accumTotalPiles += totalPiles;
        return {
          x: new Date(finishedOn).getTime(),
          y: accumTotalPiles,
        };
      });
      const newChartData = {
        series: [
          {
            name: 'Planned piling',
            data: sigmoidData,
          },
          {
            name: 'Actual piling',
            data: actualData,
          },
          // {
          //   name: 'Inspector accepted',
          //   data: inspectorCompletes,
          // },
        ],
        logDates,
      };
      return newChartData;
    }
    return {};
  }, [pageData, TableBuildStatuses]);

  // const series = Object.values(chartData);
  const { series } = chartData;
  // const series = [
  //   {
  //     name: 'series-1',
  //     data: [
  //       {
  //         x: new Date('2024-12-01').getTime(),
  //         y: 56,
  //       },
  //       {
  //         x: new Date('2024-12-20').getTime(),
  //         y: 76,
  //       },
  //     ],
  //   },
  //   {
  //     name: 'series-2',
  //     data: [
  //       {
  //         x: new Date('2024-12-01').getTime(),
  //         y: 26,
  //       },
  //       {
  //         x: new Date('2024-12-20').getTime(),
  //         y: 36,
  //       },
  //     ],
  //   },
  // ];
  const options = defaultsDeep(
    {
      // labels: Object.keys(chartData).map((k) => sentenceCase(k)),
      title: { text: 'Piling By Day S-Chart' },
    },
    defaultOptions
  );

  return (
    <Card>
      <Card.Body>
        <Chart options={options} series={series} type="line" height="350" />
      </Card.Body>
    </Card>
  );
};

export default PilingByDayDateTimeChart;

import { useMemo, useEffect } from 'react';
import { Col, Row, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { useQuery, NetworkStatus } from '@apollo/client';
import { useSelector } from 'react-redux';

import get from 'lodash.get';

import PilingSummaryRadialChart from './home/piling_summary_radial_chart';
import MechanicalSummaryRadialChart from './home/mechanical_summary_radial_chart';
import ElectricalSummaryRadialChart from './home/electrical_summary_radial_chart';

import PilingByZoneSummaryRadarChart from './home/piling_by_zone_summary_radar_chart';
import MechanicalByZoneSummaryRadarChart from './home/mechanical_by_zone_summary_radar_chart';
import ElectricalByZoneSummaryRadarChart from './home/electrical_by_zone_summary_radar_chart';

import PilingByDayDateTimeChart from './home/piling_by_day_date_time_chart';

import { renderOverlay, renderOffline, renderError } from '../components/render_helpers';

import { homePageQuery } from '../graphql/home_queries';

const Home = () => {
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(homePageQuery, {
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const enums = useMemo(() => {
    if (pageData?.enums) {
      const data = get(pageData, 'enums.enums');
      return data;
    }
    return {};
  }, [pageData]);

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }, [pageLoadedOrRefetching]);

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col>
          <h1 className="h3 text-primary">Taiohi Solar Farm</h1>
          <p>
            <strong>Infratec KPI Dashboard</strong>
          </p>
        </Col>
        <Col sm="auto">
          <ButtonToolbar>
            <ButtonGroup>
              <Button
                variant="primary"
                onClick={() => pageRefetch()}
                disabled={!settingsOnline}
              >
                Refresh Data
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={12}>
          <PilingByDayDateTimeChart
            pageData={pageData}
            TableBuildStatuses={enums.TableBuildStatuses}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={4}>
          <PilingSummaryRadialChart
            pageData={pageData}
            TableBuildStatuses={enums.TableBuildStatuses}
          />
        </Col>
        <Col sm={4}>
          <MechanicalSummaryRadialChart
            pageData={pageData}
            TableBuildStatuses={enums.TableBuildStatuses}
          />
        </Col>
        <Col sm={4}>
          <ElectricalSummaryRadialChart
            pageData={pageData}
            TableBuildStatuses={enums.TableBuildStatuses}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={4}>
          <PilingByZoneSummaryRadarChart
            pageData={pageData}
            TableBuildStatuses={enums.TableBuildStatuses}
          />
        </Col>
        <Col sm={4}>
          <MechanicalByZoneSummaryRadarChart
            pageData={pageData}
            TableBuildStatuses={enums.TableBuildStatuses}
          />
        </Col>
        <Col sm={4}>
          <ElectricalByZoneSummaryRadarChart
            pageData={pageData}
            TableBuildStatuses={enums.TableBuildStatuses}
          />
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default Home;

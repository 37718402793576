import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import { useEffect, useCallback, useState, useMemo } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import {
  Check as CheckIcon,
  X as XIcon,
  Eye as EyeIcon,
  EyeOff as EyeOffIcon,
} from 'react-feather';
import qs from 'qs';
import get from 'lodash.get';

import Api from '../lib/api';
import { subscriptionClient, hydrateCache } from '../lib/apollo_client';
import { authSet, authReset } from '../store/auth_slice';
import SubmitButtonSpinner from '../components/submit_button_spinner';

import Field from '../components/form/rff_field';
import InputField from '../components/form/input_field';
import { loginFormValidator } from '../validators';
import { setIdToken } from '../lib/local_storage';
import { toastSuccess } from '../lib/toast_helpers';

import infratecLogo from '../images/infratec_logo_transparent.png';

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [visibilityType, setVisibilityType] = useState('password');
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isAuthenticating = useSelector((state) => state.auth.isAuthenticating);
  const currentUser = useSelector((state) => state.auth.user);
  const settingsDefaultHomeRoute = useSelector(
    (state) => state.settings.defaultHomeRoute
  );
  const settingsDevannerHomeRoute = useSelector(
    (state) => state.settings.devannerHomeRoute
  );
  const settingsOnline = useSelector((state) => state.settings.online);
  const settingsDeviceOnline = useSelector((state) => state.settings.deviceOnline);
  const settingsServerOnline = useSelector((state) => state.settings.serverOnline);
  const tenantLongName = useSelector((state) => state.settings.tenantLongName);

  const redirectRoute = useMemo(() => {
    if (location.search) {
      return get(
        qs.parse(location.search, { ignoreQueryPrefix: true }),
        'redirect',
        null
      );
    }
    return null;
  }, [location]);

  const homeRoute = useMemo(() => {
    if (redirectRoute) {
      return redirectRoute;
    }
    if (currentUser?.perms) {
      if (
        Object.entries(currentUser.perms).filter(([k, v]) => k !== 'devanner' && v)
          .length > 0
      ) {
        return settingsDefaultHomeRoute;
      }
      if (currentUser.perms.devanner) {
        return settingsDevannerHomeRoute;
      }
    }
    return null;
  }, [currentUser, redirectRoute, settingsDefaultHomeRoute, settingsDevannerHomeRoute]);

  const handleVisibilityToggle = useCallback(async () => {
    setVisibilityType((prevState) => (prevState === 'password' ? 'text' : 'password'));
  }, []);

  useEffect(() => {
    // TODO update this for administrators/logistics
    if (isAuthenticated && homeRoute) {
      console.log('login');
      console.log({ homeRoute });
      navigate(homeRoute);
    }
  }, [isAuthenticated, homeRoute, navigate]);

  const handleFormSubmit = async (data) => {
    try {
      if (settingsOnline) {
        dispatch(authSet({ isAuthenticating: true }));
        const { email, password } = data;
        const loginResp = await Api.post('/auth/login', { email, password });
        const token = get(loginResp, 'data.token');
        const decoded = jwtDecode(token);
        setIdToken(token);
        dispatch(
          authSet({
            isAuthenticating: false,
            isAuthenticated: true,
            token,
            user: decoded.user,
            userId: decoded.user.id,
            tenant: decoded.tenant,
            connectionKey: decoded.connectionKey,
          })
        );
        await hydrateCache();
        // this will actually reboot the WS connection
        subscriptionClient.terminate();
        toastSuccess('Login successful');
        // the useEffect will wait for the user record to come through in state
        // navigate(redirectPath || '/goods_receipt');
      } else {
        toastSuccess('Service offine');
      }
    } catch (err) {
      console.log(err);
      if (window.$NODE_ENV !== 'development') {
        window.Rollbar.info('Failed login', {
          err,
        });
      }
      dispatch(authReset());
      navigate('/auth/logout');
      return err;
    }
    return undefined;
  };

  return (
    <>
      <div className="text-center mt-4">
        <h2>{`Welcome to ${tenantLongName}`}</h2>
        <p className="lead">Sign in to your account to continue</p>
      </div>
      <Card>
        <Card.Body style={{ height: '400px' }}>
          <div className="m-sm-4">
            <div className="text-center mb-4">
              <img
                src={infratecLogo}
                alt={tenantLongName}
                className="img-fluid rounded-circle me-2"
                style={{ height: '100px' }}
              />
            </div>
            <input style={{ display: 'none' }} type="text" name="fakeemailremembered" />
            <input
              style={{ display: 'none' }}
              type="password"
              name="fakepasswordremembered"
            />
            {settingsOnline && (
              <FinalForm onSubmit={handleFormSubmit} validate={loginFormValidator}>
                {({ handleSubmit, submitting, pristine, invalid }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Field
                      type="text"
                      name="email"
                      autoCapitalize="none"
                      component={InputField}
                      helpText="&nbsp;"
                    >
                      Email
                    </Field>
                    <Field
                      name="password"
                      type={visibilityType}
                      autoCapitalize="none"
                      component={InputField}
                      helpText="&nbsp;"
                      appendButton={
                        <Button
                          variant="outline-secondary"
                          id="button-toggle-visibility"
                          onClick={handleVisibilityToggle}
                        >
                          {visibilityType === 'password' ? <EyeIcon /> : <EyeOffIcon />}
                        </Button>
                      }
                    >
                      Password
                    </Field>
                    <Form.Group as={Row}>
                      <Col sm={12}>
                        <div className="float-end">
                          <Button
                            className="float-end"
                            type="submit"
                            variant="primary"
                            disabled={pristine || invalid || submitting}
                          >
                            {(isAuthenticating || submitting) && <SubmitButtonSpinner />}
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Form.Group>
                  </Form>
                )}
              </FinalForm>
            )}
            {/* {!settingsOnline && !delayExpired && <Loader fadeIn="quarter" />} */}
            {!settingsOnline && (
              <div className="text-center mt-4">
                <p className="lead">You are either offline or FieldDesk is unavailable</p>
                <p>
                  Device Online:
                  <span className="ml-4">
                    {settingsDeviceOnline ? (
                      <CheckIcon color="green" />
                    ) : (
                      <XIcon color="red" />
                    )}
                  </span>
                </p>
                <p>
                  Service Online:
                  <span className="ml-4">
                    {settingsServerOnline ? (
                      <CheckIcon color="green" />
                    ) : (
                      <XIcon color="red" />
                    )}
                  </span>
                </p>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

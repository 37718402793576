import { useMemo } from 'react';
import {
  Button,
  Col,
  Row,
  Card,
  ButtonToolbar,
  ButtonGroup,
  Form,
} from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useNavigate, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import { v4 as uuidv4 } from 'uuid';

import cloneDeep from 'lodash.clonedeep';

import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import { settingsSet } from '../store/settings_slice';

import LinkContainer from '../components/link_container';
import { renderOverlay, renderError, renderOffline } from '../components/render_helpers';
import SubmitButtonSpinner from '../components/submit_button_spinner';
import Field from '../components/form/rff_field';
import InputField from '../components/form/input_field';
import { coerceInput, pickValues, handleSubmitError } from '../lib/utils';
import {
  siteFormPageQuery,
  siteCreate as siteCreateMutation,
  siteUpdate as siteUpdateMutation,
} from '../graphql/site_queries';
import * as updateFunctions from '../update_functions';
import { siteDefaultValues } from '../defaults';
import { siteFormValidator } from '../validators';
import { siteWhiteList } from '../white_lists';

const SiteForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const [siteCreate] = useMutation(siteCreateMutation);
  const [siteUpdate] = useMutation(siteUpdateMutation);
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    networkStatus: pageNetworkStatus,
  } = useQuery(siteFormPageQuery, {
    variables: {
      hasSiteId: !!params.id,
      siteId: params.id || 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const initialValues = useMemo(() => {
    if (pageData?.site) {
      const values = pickValues(pageData.site, siteWhiteList);
      return values;
    }
    return siteDefaultValues;
  }, [pageData]);

  const onCancel = () => {
    navigate('/sites');
  };

  const onFormSubmit = async (data) => {
    const uuid = uuidv4();
    const submitData = cloneDeep(data);
    let mutation;
    let mutationMessageAction;
    const input = coerceInput(submitData);
    const mutationData = {
      variables: { input },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'SiteType',
        recordId: params.id || uuid,
      },
    };
    if (params.id) {
      mutation = siteUpdate;
      mutationMessageAction = 'update';
      mutationData.variables.id = params.id;
      mutationData.context.mutationType = 'UPDATE';
      mutationData.update = updateFunctions.siteUpdate;
      mutationData.optimisticResponse = updateFunctions.optimistic(
        'siteUpdate',
        mutationData
      );
    } else {
      mutation = siteCreate;
      mutationData.context.mutationType = 'CREATE';
      mutationMessageAction = 'create';
      mutationData.update = updateFunctions.siteCreate;
      mutationData.optimisticResponse = updateFunctions.optimistic(
        'siteCreate',
        mutationData
      );
    }
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await mutation(mutationData);
        toastSuccess(`Site ${mutationMessageAction} succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        navigate('/sites');
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      mutation(mutationData);
      toastWarning(
        `Site ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      navigate('/sites');
    }
    return undefined;
  };

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm={12}>
          <div className="float-none">
            <div className="float-start">
              <h1 className="h3 mb-3">{params.id ? 'Edit Site' : 'New Site'}</h1>
            </div>
            <div className="float-end">
              <LinkContainer to="/sites">
                <Button className="me-2" variant="primary">
                  All Sites
                </Button>
              </LinkContainer>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <FinalForm
            initialValues={initialValues}
            onSubmit={(data) => onFormSubmit(data)}
            validate={siteFormValidator}
            mutators={{ setFieldTouched }}
          >
            {({ handleSubmit, pristine, submitting }) => (
              <form noValidate>
                <Card>
                  <Card.Body>
                    <Field
                      name="name"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={8}
                      size="lg"
                    >
                      Full Name
                    </Field>
                    <Field
                      name="projectNumber"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={8}
                      size="lg"
                    >
                      Project Number
                    </Field>
                    <Form.Group as={Row}>
                      <Col sm={12}>
                        <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                          <ButtonGroup className="me-2">
                            <Button
                              variant="danger"
                              onClick={onCancel}
                              disabled={submitting}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="button"
                              variant="primary"
                              disabled={pristine || submitting}
                              onClick={handleSubmit}
                            >
                              {submitting && <SubmitButtonSpinner />}
                              {params.id ? 'Update' : 'Create'}
                            </Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                      </Col>
                    </Form.Group>
                  </Card.Body>
                </Card>
              </form>
            )}
          </FinalForm>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default SiteForm;

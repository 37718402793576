import { useState, useMemo, useCallback } from 'react';
import {
  Button,
  Col,
  Row,
  Card,
  ButtonToolbar,
  ButtonGroup,
  Form,
} from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useNavigate, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import { v4 as uuidv4 } from 'uuid';

import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';

import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import { settingsSet } from '../store/settings_slice';
import ProductSupplierCatalogItemFormModal from './product_form/product_supplier_catalog_item_form_modal';
import ProductSupplierCatalogItemList from './product_form/product_supplier_catalog_item_list';

import ProductProductFormModal from './product_form/product_product_form_modal';
import ProductProductList from './product_form/product_product_list';

import LinkContainer from '../components/link_container';
import { renderOverlay, renderError, renderOffline } from '../components/render_helpers';
import SubmitButtonSpinner from '../components/submit_button_spinner';
import Field from '../components/form/rff_field';
import InputField from '../components/form/input_field';
import { coerceInput, pickValues, handleSubmitError } from '../lib/utils';
import {
  productFormPageQuery,
  productCreate as productCreateMutation,
  productUpdate as productUpdateMutation,
} from '../graphql/product_queries';
import * as updateFunctions from '../update_functions';
import { productDefaultValues } from '../defaults';
import { productFormValidator } from '../validators';
import { productWhiteList } from '../white_lists';

const ProductForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [
    showProductSupplierCatalogItemFormModal,
    setShowProductSupplierCatalogItemFormModal,
  ] = useState(false);
  const [selectedProductSupplierCatalogItem, setSelectedProductSupplierCatalogItem] =
    useState({});
  const [showProductProductFormModal, setShowProductProductFormModal] = useState(false);
  const [selectedProductProduct, setSelectedProductProduct] = useState({});
  const currentUser = useSelector((state) => state.auth.user);
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const [productCreate] = useMutation(productCreateMutation);
  const [productUpdate] = useMutation(productUpdateMutation);
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(productFormPageQuery, {
    variables: {
      hasProductId: !!params.id,
      productId: params.id || 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const initialValues = useMemo(() => {
    if (pageData?.product) {
      const values = pickValues(pageData.product, productWhiteList);
      return values;
    }
    return productDefaultValues;
  }, [pageData]);

  const selectableProducts = useMemo(() => {
    if (params.id) {
      return get(pageData, 'productList', []).filter(
        (p) => p.id !== parseInt(params.id, 10)
      );
    }
    return [];
  }, [pageData, params]);

  const handleProductSupplierCatalogItemEditClicked = useCallback(
    (productSupplierCatalogItemId) => {
      const productSupplierCatalogItems = get(
        pageData,
        'product.productSupplierCatalogItems',
        []
      );
      const productSupplierCatalogItem = productSupplierCatalogItems.find(
        (sci) => sci.id === parseInt(productSupplierCatalogItemId, 10)
      );
      if (productSupplierCatalogItem) {
        setSelectedProductSupplierCatalogItem(productSupplierCatalogItem);
        setShowProductSupplierCatalogItemFormModal(true);
      }
    },
    [pageData]
  );

  const handleProductProductEditClicked = useCallback(
    (productProductId) => {
      const productProducts = get(
        pageData,
        'product.productProductProductAssemblies',
        []
      );
      const productProduct = productProducts.find(
        (sci) => sci.id === parseInt(productProductId, 10)
      );
      if (productProduct) {
        setSelectedProductProduct(productProduct);
        setShowProductProductFormModal(true);
      }
    },
    [pageData]
  );

  const onCancel = () => {
    navigate('/products');
  };

  const onFormSubmit = async (data) => {
    const uuid = uuidv4();
    const submitData = cloneDeep(data);
    let mutation;
    let mutationMessageAction;
    const input = coerceInput(submitData);
    const mutationData = {
      variables: { input },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'ProductType',
        recordId: params.id || uuid,
      },
    };
    if (params.id) {
      mutation = productUpdate;
      mutationMessageAction = 'update';
      mutationData.variables.id = params.id;
      mutationData.context.mutationType = 'UPDATE';
      mutationData.update = updateFunctions.productUpdate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'productUpdate',
        mutationData,
        currentData: pageData.product,
      });
    } else {
      mutation = productCreate;
      mutationData.context.mutationType = 'CREATE';
      mutationMessageAction = 'create';
      mutationData.update = updateFunctions.productCreate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'productCreate',
        mutationData,
      });
    }
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await mutation(mutationData);
        toastSuccess(`Product ${mutationMessageAction} succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        navigate('/products');
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      mutation(mutationData);
      toastWarning(
        `Product ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      navigate('/products');
    }
    return undefined;
  };

  const handleProductSupplierCatalogItemFormModalClick = () => {
    setShowProductSupplierCatalogItemFormModal(true);
  };

  const handleProductSupplierCatalogItemFormModalCancel = () => {
    setShowProductSupplierCatalogItemFormModal(false);
    setSelectedProductSupplierCatalogItem({});
  };

  const handleProductSupplierCatalogItemFormModalComplete = () => {
    pageRefetch();
    setShowProductSupplierCatalogItemFormModal(false);
    setSelectedProductSupplierCatalogItem({});
  };

  const handleProductProductFormModalClick = () => {
    setShowProductProductFormModal(true);
  };

  const handleProductProductFormModalCancel = () => {
    setShowProductProductFormModal(false);
    setSelectedProductProduct({});
  };

  const handleProductProductFormModalComplete = () => {
    pageRefetch();
    setShowProductProductFormModal(false);
    setSelectedProductProduct({});
  };

  const renderContent = () => (
    <>
      {params.id && (
        <ProductSupplierCatalogItemFormModal
          show={showProductSupplierCatalogItemFormModal}
          supplierCatalogItems={get(pageData, ['supplierCatalogItemList'])}
          productId={get(pageData, ['product', 'id'])}
          productName={get(pageData, ['product', 'name'])}
          onCancel={handleProductSupplierCatalogItemFormModalCancel}
          onComplete={handleProductSupplierCatalogItemFormModalComplete}
          settingsTenant={settingsTenant}
          settingsOnline={settingsOnline}
          productSupplierCatalogItem={selectedProductSupplierCatalogItem}
        />
      )}
      {params.id && (
        <ProductProductFormModal
          show={showProductProductFormModal}
          products={selectableProducts}
          productId={get(pageData, ['product', 'id'])}
          productName={get(pageData, ['product', 'name'])}
          onCancel={handleProductProductFormModalCancel}
          onComplete={handleProductProductFormModalComplete}
          settingsTenant={settingsTenant}
          settingsOnline={settingsOnline}
          productProduct={selectedProductProduct}
        />
      )}
      <Row className="mt-4 mb-3">
        <Col sm={12}>
          <div className="float-none">
            <div className="float-start">
              <h1 className="h3 mb-3">{params.id ? 'Edit Product' : 'New Product'}</h1>
            </div>
            <div className="float-end">
              <LinkContainer to="/products">
                <Button className="me-2" variant="primary">
                  All Products
                </Button>
              </LinkContainer>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <FinalForm
            initialValues={initialValues}
            onSubmit={(data) => onFormSubmit(data)}
            validate={productFormValidator}
            mutators={{ setFieldTouched }}
          >
            {({ handleSubmit, pristine, submitting }) => (
              <form noValidate>
                <Card>
                  <Card.Body>
                    <Field
                      name="name"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={8}
                      size="lg"
                    >
                      Product Name
                    </Field>
                    <Field
                      name="productCategoryId"
                      component={InputField}
                      asElement="select"
                      selectOptions={get(pageData, 'productCategoryList', [])}
                      defaultSelectOptionText="select product category..."
                      labelWidth={4}
                      inputWidth={4}
                      size="lg"
                    >
                      Product Category
                    </Field>
                    <Form.Group as={Row}>
                      <Col sm={12}>
                        <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                          <ButtonGroup className="me-2">
                            <Button
                              variant="danger"
                              onClick={onCancel}
                              disabled={submitting}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="button"
                              variant="primary"
                              disabled={pristine || submitting}
                              onClick={handleSubmit}
                            >
                              {submitting && <SubmitButtonSpinner />}
                              {params.id ? 'Update' : 'Create'}
                            </Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                      </Col>
                    </Form.Group>
                  </Card.Body>
                </Card>
              </form>
            )}
          </FinalForm>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm={12}>
          <Card>
            <Card.Header>Product Subassemblies</Card.Header>
            <Card.Body>
              {params.id ? (
                <>
                  <ProductProductList
                    productProducts={get(
                      pageData,
                      ['product', 'productProductProductAssemblies'],
                      []
                    )}
                    currentUser={currentUser}
                    settingsTenant={settingsTenant}
                    settingsOnline={settingsOnline}
                    onEdit={handleProductProductEditClicked}
                    onDelete={() => pageRefetch()}
                  />
                  <Button
                    className="me-2"
                    type="button"
                    variant="primary"
                    onClick={handleProductProductFormModalClick}
                  >
                    Add Product Assembly
                  </Button>
                </>
              ) : (
                <p>Create a new Product to begin adding Subassemblies</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm={12}>
          <Card>
            <Card.Header>Supplier Catalog Items</Card.Header>
            <Card.Body>
              {params.id ? (
                <>
                  <ProductSupplierCatalogItemList
                    productSupplierCatalogItems={get(
                      pageData,
                      ['product', 'productSupplierCatalogItems'],
                      []
                    )}
                    currentUser={currentUser}
                    settingsTenant={settingsTenant}
                    settingsOnline={settingsOnline}
                    onEdit={handleProductSupplierCatalogItemEditClicked}
                    onDelete={() => pageRefetch()}
                  />

                  <Button
                    className="me-2"
                    type="button"
                    variant="primary"
                    onClick={handleProductSupplierCatalogItemFormModalClick}
                  >
                    Add Supplier Catalog Item
                  </Button>
                </>
              ) : (
                <p>Create a new Product to begin adding Items</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default ProductForm;

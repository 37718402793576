import { useMemo, useCallback, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Row,
  Card,
  ButtonToolbar,
  ButtonGroup,
  Form,
  Tabs,
  Tab,
} from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useNavigate, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import { v4 as uuidv4 } from 'uuid';
import readXlsxFile, { readSheetNames } from 'read-excel-file';
import objectHash from 'object-hash';
import { capitalCase } from 'change-case';
import { Queue } from 'async-await-queue';

import cloneDeep from 'lodash.clonedeep';
import difference from 'lodash.difference';
import get from 'lodash.get';
import groupBy from 'lodash.groupby';
import pick from 'lodash.pick';
import transform from 'lodash.transform';
import uniq from 'lodash.uniq';

import { srackSchema } from '../lib/importers/srack_schema';

import { renderOverlay, renderError, renderOffline } from '../components/render_helpers';
import SubmitButtonSpinner from '../components/submit_button_spinner';
import Field from '../components/form/rff_field';
import InputField from '../components/form/input_field';

import { srackImportFormPageQuery } from '../graphql/import_queries';
import { siteCreate as siteCreateMutation } from '../graphql/site_queries';
import { siteLocationCreate as siteLocationCreateMutation } from '../graphql/site_location_queries';
import { supplierCreate as supplierCreateMutation } from '../graphql/supplier_queries';
import {
  consignmentCreate as consignmentCreateMutation,
  consignmentDelete as consignmentDeleteMutation,
} from '../graphql/consignment_queries';
import {
  consignmentItemCreate as consignmentItemCreateMutation,
  // consignmentItemDelete as consignmentItemDeleteMutation,
} from '../graphql/consignment_item_queries';
import * as updateFunctions from '../update_functions';
import { settingsSet } from '../store/settings_slice';

const consignmentQueue = new Queue(1, 25);
const consignmentItemQueue = new Queue(1, 25);
const priority = -1;

const SrackImportForm = () => {
  // we're looking for a supplier record called this
  const supplierName = 'S-Rack';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [fileError, setFileError] = useState('');
  const [fileRows, setFileRows] = useState([]);
  const [fileErrors, setFileErrors] = useState([]);
  const [projectCode, setProjectCode] = useState('');
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const [siteCreate] = useMutation(siteCreateMutation);
  const [siteLocationCreate] = useMutation(siteLocationCreateMutation);
  const [supplierCreate] = useMutation(supplierCreateMutation);
  const [consignmentCreate] = useMutation(consignmentCreateMutation);
  const [consignmentItemCreate] = useMutation(consignmentItemCreateMutation);
  const [consignmentDelete] = useMutation(consignmentDeleteMutation);
  // const [consignmentItemDelete] = useMutation(consignmentItemDeleteMutation);
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    networkStatus: pageNetworkStatus,
    refetch: pageRefetch,
  } = useQuery(srackImportFormPageQuery, {
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const handleFileChange = useCallback(async (name, onChange, e) => {
    const file = get(e, 'target.files.0');
    if (file) {
      const newProjectCode = get(file.name.match(/^(\d+)\s*|-.+/), '1');
      if (newProjectCode) {
        setFileError('');
        setProjectCode(newProjectCode);
        const sheetNames = await readSheetNames(file);
        try {
          if (sheetNames.includes('WITHZONES')) {
            const { rows, errors = [] } = await readXlsxFile(file, {
              schema: srackSchema,
              sheet: 'WITHZONES',
              transformData(data) {
                let newData;
                // remove the title line if exists
                if (data[0][0] !== 'Shipment Number') {
                  newData = data.slice(1);
                }
                // ignore the additional header style rows
                newData = newData.filter(
                  (d, index) => index === 0 || d[0] !== 'Shipment Number'
                );
                return newData;
              },
            });
            const importTimestamp = 'initial';
            let sharedShipmentNumber;
            let sharedContainerType;
            let sharedConsignmentItemReference;
            let sharedConsignmentItemReferenceSuffix;
            const newFileRows = rows.map((row, index) => {
              const newRow = cloneDeep(row);
              const rowIndex = index + 2;
              // defaults
              if (newRow.containerReference) {
                // imported container type has some nasty unprintable unicodes
                newRow.containerReference = newRow.containerReference.replace(
                  /[^a-zA-Z0-9.()]+/gi,
                  ')'
                );
              }
              if (newRow.shipmentNumber) {
                newRow.shipmentNumber = `${newRow.shipmentNumber} (${importTimestamp})`;
              }
              newRow.quantity = Math.round(newRow.quantity || 0);
              newRow.weightKg = Math.round(newRow.weightKg || 0);
              newRow.consignmentItemType = capitalCase(newRow.consignmentItemType);

              // merged cell processing
              const nextRowIndex = rowIndex + 1;
              // handle merged cells in the shipmentNumber column
              const rowShipmentNumberError = errors.find(
                (error) =>
                  error.row === rowIndex &&
                  error.column === 'Shipment Number' &&
                  error.error === 'required'
              );
              const nextRowShipmentNumberError = errors.find(
                (error) =>
                  error.row === nextRowIndex &&
                  error.column === 'Shipment Number' &&
                  error.error === 'required'
              );
              if (
                newRow.shipmentNumber &&
                !rowShipmentNumberError &&
                nextRowShipmentNumberError
              ) {
                sharedShipmentNumber = newRow.shipmentNumber;
              }
              if (
                sharedShipmentNumber &&
                !newRow.shipmentNumber &&
                rowShipmentNumberError
              ) {
                newRow.shipmentNumber = sharedShipmentNumber;
              }
              if (
                sharedShipmentNumber &&
                newRow.shipmentNumber &&
                !nextRowShipmentNumberError
              ) {
                sharedShipmentNumber = '';
              }
              // handle merged cells in the containerReference column
              const rowContainerTypeError = errors.find(
                (error) =>
                  error.row === rowIndex &&
                  error.column === 'Container Type' &&
                  error.error === 'required'
              );
              const nextRowContainerTypeError = errors.find(
                (error) =>
                  error.row === nextRowIndex &&
                  error.column === 'Container Type' &&
                  error.error === 'required'
              );
              if (
                newRow.containerReference &&
                !rowContainerTypeError &&
                nextRowContainerTypeError
              ) {
                sharedContainerType = newRow.containerReference;
              }
              if (
                sharedContainerType &&
                !newRow.containerReference &&
                rowContainerTypeError
              ) {
                newRow.containerReference = sharedContainerType;
              }
              if (
                sharedContainerType &&
                newRow.containerReference &&
                !nextRowContainerTypeError
              ) {
                sharedContainerType = '';
              }

              // handle merged cells in the consignmentItemReference column
              const rowConsignmentItemReferenceError = errors.find(
                (error) =>
                  error.row === rowIndex &&
                  error.column === 'Packing Item Number' &&
                  error.error === 'required'
              );
              const nextRowConsignmentItemReferenceError = errors.find(
                (error) =>
                  error.row === nextRowIndex &&
                  error.column === 'Packing Item Number' &&
                  error.error === 'required'
              );
              if (
                newRow.consignmentItemReference &&
                !rowConsignmentItemReferenceError &&
                nextRowConsignmentItemReferenceError
              ) {
                sharedConsignmentItemReference = newRow.consignmentItemReference;
                sharedConsignmentItemReferenceSuffix = 'a';
              }
              if (
                sharedConsignmentItemReference &&
                !newRow.consignmentItemReference &&
                rowConsignmentItemReferenceError
              ) {
                newRow.consignmentItemReference = `${sharedConsignmentItemReference}${sharedConsignmentItemReferenceSuffix}`;
                sharedConsignmentItemReferenceSuffix = String.fromCharCode(
                  sharedConsignmentItemReferenceSuffix.charCodeAt(0) + 1
                );
              }
              if (
                sharedConsignmentItemReference &&
                newRow.consignmentItemReference &&
                !nextRowConsignmentItemReferenceError
              ) {
                sharedConsignmentItemReference = '';
                sharedConsignmentItemReferenceSuffix = '';
              }
              // const hash = objectHash(newRow);
              // newRow.importHash = hash;
              // newRow.rowIndex = rowIndex;
              return newRow;
            });
            setFileError('');
            setFileRows(newFileRows);
            setFileErrors(errors);
            onChange(file);
          } else {
            setFileError('Spreadsheet must include a sheet titled "WITHZONES"');
            setFileRows([]);
            setFileErrors([]);
          }
        } catch (err) {
          setFileError(err.message);
          setFileRows([]);
          setFileErrors([]);
        }
      } else {
        setFileError('Spreadsheet name must begin with a project code');
        setProjectCode('');
        setFileRows([]);
        setFileErrors([]);
      }
    }
  }, []);

  const onCancel = () => {
    navigate('/');
  };

  const onFormSubmit = async () => {
    const existingSupplierNames = uniq(get(pageData, 'supplierList').map((s) => s.name));
    const rowSupplierNames = [supplierName];
    const createableSupplierNames = difference(rowSupplierNames, existingSupplierNames);
    const supplierPromises = createableSupplierNames.map(
      async (createableSupplierName) => {
        const uuid = uuidv4();
        const mutationData = {
          variables: {
            input: {
              name: createableSupplierName,
            },
          },
          context: {
            serializationKey: settingsTenant,
            tracked: true,
            recordType: 'SupplierType',
            recordId: uuid,
            mutationType: 'CREATE',
          },
        };
        mutationData.update = updateFunctions.supplierCreate;
        mutationData.optimisticResponse = updateFunctions.optimisticNew({
          mutationName: 'supplierCreate',
          mutationData,
        });
        const resp = await supplierCreate(mutationData);
        return get(resp, 'data.supplierCreate');
      }
    );
    const supplierResponses = await Promise.all(supplierPromises);
    const suppliers = [...get(pageData, 'supplierList'), ...supplierResponses];
    console.log({ suppliers });
    // sites
    const existingSiteProjectNumbers = uniq(
      get(pageData, 'siteList').map((s) => s.projectNumber)
    );
    const rowSiteProjectNumbers = [projectCode];
    const createableSiteProjectNumbers = difference(
      rowSiteProjectNumbers,
      existingSiteProjectNumbers
    );
    const sitePromises = createableSiteProjectNumbers.map(
      async (createableSiteProjectNumber) => {
        const uuid = uuidv4();
        const mutationData = {
          variables: {
            input: {
              name: createableSiteProjectNumber,
              projectNumber: createableSiteProjectNumber,
            },
          },
          context: {
            serializationKey: settingsTenant,
            tracked: true,
            recordType: 'SiteType',
            recordId: uuid,
            mutationType: 'CREATE',
          },
        };
        mutationData.update = updateFunctions.siteCreate;
        mutationData.optimisticResponse = updateFunctions.optimisticNew({
          mutationName: 'siteCreate',
          mutationData,
        });
        const resp = await siteCreate(mutationData);
        return get(resp, 'data.siteCreate');
      }
    );
    const siteResponses = await Promise.all(sitePromises);
    const sites = [...get(pageData, 'siteList'), ...siteResponses];
    console.log({ sites });

    // siteLocations
    const siteLocationType = get(pageData, 'enums.enums.SiteLocationTypes.ZONE');
    const site = sites.find((s) => s.projectNumber === projectCode);
    const siteId = site.id;
    const existingSiteLocationNames = uniq(
      get(site, 'siteLocations', []).map((sl) => sl.name)
    );
    const rowSiteLocationNames = uniq(fileRows.map((r) => r.siteLocationName));
    const createableSiteLocationNames = difference(
      rowSiteLocationNames,
      existingSiteLocationNames
    );
    const siteLocationPromises = createableSiteLocationNames.map(
      async (createableSiteLocationName) => {
        const uuid = uuidv4();
        const mutationData = {
          variables: {
            input: {
              name: createableSiteLocationName,
              siteId,
              siteLocationType,
            },
          },
          context: {
            serializationKey: settingsTenant,
            tracked: true,
            recordType: 'SiteLocationType',
            recordId: uuid,
            mutationType: 'CREATE',
          },
        };
        mutationData.update = updateFunctions.siteLocationCreate;
        mutationData.optimisticResponse = updateFunctions.optimisticNew({
          mutationName: 'siteLocationCreate',
          mutationData,
        });
        const resp = await siteLocationCreate(mutationData);
        return get(resp, 'data.siteLocationCreate');
      }
    );
    const siteLocationResponses = await Promise.all(siteLocationPromises);
    const siteLocations = [
      ...get(pageData, 'siteLocationList'),
      ...siteLocationResponses,
    ];
    console.log({ siteLocations });

    // Consignments
    const supplier = suppliers.find((s) => s.name === supplierName);
    const supplierId = supplier.id;

    const groupedFileRowsByContainerType = groupBy(fileRows, 'containerReference');

    const groupedConsignmentItemReferencesByContainerIdentifier = transform(
      groupedFileRowsByContainerType,
      (accum, v, k) => {
        const consignmentItemReferences = v.map((r) => r.consignmentItemReference).sort();
        const containerIdentifier = objectHash(consignmentItemReferences);
        /* eslint-disable no-param-reassign */
        accum[containerIdentifier] = { containerReference: k, consignmentItemReferences };
      },
      {}
    );
    const containerIdentifierByConsignmentItemReference = transform(
      groupedConsignmentItemReferencesByContainerIdentifier,
      (accum, v, k) => {
        /* eslint-disable no-return-assign */
        v.consignmentItemReferences.forEach((cir) => (accum[cir] = k));
      },
      {}
    );
    const existingContainerIdentifiers = uniq(
      get(pageData, 'consignmentList').map((s) => s.containerIdentifier)
    );
    const rowContainerIdentifiers = Object.keys(
      groupedConsignmentItemReferencesByContainerIdentifier
    );
    const createableContainerIdentifiers = difference(
      rowContainerIdentifiers,
      existingContainerIdentifiers
    );
    const consignmentPromises = createableContainerIdentifiers.map(
      async (createableContainerIdentifier) => {
        await consignmentQueue.wait(createableContainerIdentifier, priority);
        const uuid = uuidv4();
        const identifierData =
          groupedConsignmentItemReferencesByContainerIdentifier[
            createableContainerIdentifier
          ];
        const { containerReference } = identifierData;
        const firstRow = fileRows.find(
          (r) => r.containerReference === containerReference
        );
        const { shipmentNumber } = firstRow;
        const mutationData = {
          variables: {
            input: {
              containerIdentifier: createableContainerIdentifier,
              siteId,
              supplierId,
              shipmentNumber,
              containerReference,
            },
          },
          context: {
            serializationKey: settingsTenant,
            tracked: true,
            recordType: 'ConsignmentType',
            recordId: uuid,
            mutationType: 'CREATE',
          },
        };
        mutationData.update = updateFunctions.consignmentCreate;
        mutationData.optimisticResponse = updateFunctions.optimisticNew({
          mutationName: 'consignmentCreate',
          mutationData,
        });
        try {
          const resp = await consignmentCreate(mutationData);
          return get(resp, 'data.consignmentCreate');
        } finally {
          consignmentQueue.end(createableContainerIdentifier);
        }
      }
    );
    const consignmentResponses = await Promise.all(consignmentPromises);
    const consignments = [...get(pageData, 'consignmentList'), ...consignmentResponses];
    console.log({ consignments });

    const consignmentIds = consignments.map((sc) => sc.id);

    const existingConsignmentItemReferences = uniq(
      get(pageData, 'consignmentItemList')
        .filter(
          (sci) =>
            sci.consignmentItemReference && consignmentIds.includes(sci.consignmentId)
        )
        .map((sci) => sci.consignmentItemReference)
    );
    const rowConsignmentItemReferences = uniq(
      fileRows.map((r) => r.consignmentItemReference)
    );

    // const rowConsignmentItemReferences = uniq(
    //   fileRows.slice(0, 2).map((r) => r.consignmentItemReference)
    // );

    const createableConsignmentItemReferences = difference(
      rowConsignmentItemReferences,
      existingConsignmentItemReferences
    );
    const consignmentItemPromises = createableConsignmentItemReferences.map(
      async (createableConsignmentItemReference) => {
        await consignmentItemQueue.wait(createableConsignmentItemReference, priority);
        const sourceRow = fileRows.find(
          (r) => r.consignmentItemReference === createableConsignmentItemReference
        );
        const uuid = uuidv4();
        const input = pick(sourceRow, [
          'description',
          'quantity',
          'weightKg',
          'consignmentItemReference',
          'consignmentItemType',
          'consignmentItemNumber',
        ]);

        const containerIdentifier =
          containerIdentifierByConsignmentItemReference[input.consignmentItemReference];

        const consignmentId = consignments.find(
          (c) => c.containerIdentifier === containerIdentifier
        ).id;
        input.consignmentId = consignmentId;

        const siteLocationId = siteLocations.find(
          (sl) => sl.name === sourceRow.siteLocationName
        ).id;
        input.siteLocationId = siteLocationId;

        const mutationData = {
          variables: { input },
          context: {
            serializationKey: settingsTenant,
            tracked: true,
            recordType: 'ConsignmentItemType',
            recordId: uuid,
            mutationType: 'CREATE',
          },
        };
        mutationData.update = updateFunctions.consignmentItemCreate;
        mutationData.optimisticResponse = updateFunctions.optimisticNew({
          mutationName: 'consignmentItemCreate',
          mutationData,
        });
        try {
          const resp = await consignmentItemCreate(mutationData);
          return get(resp, 'data.consignmentItemCreate');
        } finally {
          consignmentItemQueue.end(createableConsignmentItemReference);
        }
      }
    );
    const consignmentItemResponses = await Promise.all(consignmentItemPromises);
    const consignmentItems = [
      ...get(pageData, 'consignmentItemList'),
      ...consignmentItemResponses,
    ];
    console.log({ consignmentItems });
  };

  const consignmentDeleteAllClicked = async () => {
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    const consignmentPromises = get(pageData, ['consignmentList'], []).map(
      async ({ id: consignmentId, supplier = {} }) => {
        await consignmentQueue.wait(consignmentId, priority);
        const { name } = supplier;
        try {
          if (name !== supplierName) {
            return true;
          }
          const mutationData = {
            variables: { id: consignmentId },
            context: {
              serializationKey: settingsTenant,
              tracked: true,
              recordType: 'ConsignmentType',
              recordId: consignmentId,
              mutationType: 'DELETE',
            },
            update: updateFunctions.consignmentDelete,
          };
          mutationData.optimisticResponse = updateFunctions.optimistic(
            'consignmentDelete',
            mutationData
          );
          const resp = await consignmentDelete(mutationData);
          return get(resp, 'data.consignmentDelete');
        } finally {
          consignmentQueue.end(consignmentId);
        }
      }
    );
    await Promise.all(consignmentPromises);
    dispatch(
      settingsSet({
        mutating: false,
      })
    );
  };

  // const consignmentItemDeleteAllClicked = async () => {
  //   dispatch(
  //     settingsSet({
  //       mutating: true,
  //     })
  //   );
  //   const consignmentItemPromises = get(pageData, ['consignmentItemList'], []).map(
  //     async ({ id: consignmentItemId }) => {
  //       await consignmentItemQueue.wait(consignmentItemId, priority);
  //       const mutationData = {
  //         variables: { id: consignmentItemId },
  //         context: {
  //           serializationKey: settingsTenant,
  //           tracked: true,
  //           recordType: 'ConsignmentItemType',
  //           recordId: consignmentItemId,
  //           mutationType: 'DELETE',
  //         },
  //         update: updateFunctions.consignmentItemDelete,
  //       };
  //       mutationData.optimisticResponse = updateFunctions.optimistic(
  //         'consignmentItemDelete',
  //         mutationData
  //       );
  //       try {
  //         const resp = await consignmentItemDelete(mutationData);
  //         return get(resp, 'data.consignmentItemDelete');
  //       } finally {
  //         consignmentItemQueue.end(consignmentItemId);
  //       }
  //     }
  //   );
  //   await Promise.all(consignmentItemPromises);
  //   dispatch(
  //     settingsSet({
  //       mutating: false,
  //     })
  //   );
  // };

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm={12}>
          <div className="float-none">
            <div className="float-start">
              <h1 className="h3 mb-3">
                {params.id ? 'Edit Srack Import' : 'New Srack Import'}
              </h1>
            </div>
          </div>
        </Col>
      </Row>
      {fileError && (
        <Row>
          <Col>
            <Alert variant="danger">{fileError}</Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <FinalForm
            onSubmit={(data) => onFormSubmit(data)}
            mutators={{ setFieldTouched }}
          >
            {({ handleSubmit, pristine, submitting }) => (
              <form noValidate>
                <Card>
                  <Card.Body>
                    <Field
                      type="file"
                      name="file"
                      labelWidth={4}
                      inputWidth={4}
                      size="lg"
                      component={InputField}
                      customOnChange={handleFileChange}
                    />
                    <Form.Group as={Row}>
                      <Col sm={12}>
                        <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                          <ButtonGroup className="me-2">
                            <Button
                              variant="danger"
                              onClick={consignmentDeleteAllClicked}
                              disabled={!settingsOnline}
                            >
                              Delete all S-Rack Consignments and Items
                            </Button>
                            {/* <Button
                              variant="danger"
                              onClick={consignmentItemDeleteAllClicked}
                              disabled={!settingsOnline}
                            >
                              Delete all Consignment Items
                            </Button> */}
                          </ButtonGroup>

                          <ButtonGroup className="me-2">
                            <Button
                              variant="primary"
                              onClick={() => pageRefetch()}
                              disabled={!settingsOnline}
                            >
                              Refresh
                            </Button>
                            <Button
                              variant="danger"
                              onClick={onCancel}
                              disabled={submitting}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="button"
                              variant="primary"
                              disabled={pristine || submitting}
                              onClick={handleSubmit}
                            >
                              {submitting && <SubmitButtonSpinner />}
                              {params.id ? 'Update' : 'Create'}
                            </Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                      </Col>
                    </Form.Group>
                  </Card.Body>
                </Card>
              </form>
            )}
          </FinalForm>
        </Col>
      </Row>
      {projectCode && (
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <p>{`Project Code: ${projectCode}`}</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      {fileRows.length > 0 && (
        <Row>
          <Col>
            <Tabs defaultActiveKey="rows" id="uncontrolled-tab-example" className="mb-3">
              <Tab eventKey="rows" title="Rows">
                <pre>{JSON.stringify(fileRows, undefined, 2)}</pre>
              </Tab>
              <Tab eventKey="errors" title="Errors">
                <pre>{JSON.stringify(fileErrors, undefined, 2)}</pre>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default SrackImportForm;

import { useMemo } from 'react';
import { Col, Row, Card, Button } from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { validate as uuidValidate } from 'uuid';

import LinkContainer from '../components/link_container';
import { renderOverlay, renderOffline, renderError } from '../components/render_helpers';
import ReactTable from '../components/react_table/react_table';
import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import {
  productListPageQuery,
  productDelete as productDeleteMutation,
} from '../graphql/product_queries';
import { settingsSet } from '../store/settings_slice';
import * as updateFunctions from '../update_functions';

const ProductList = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const [productDelete] = useMutation(productDeleteMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
    client: pageClient,
  } = useQuery(productListPageQuery, {
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ productList }) => {
      // write an individual query for every product in the list
      updateFunctions.productList(pageClient, productList);
    },
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const productDeleteClicked = async (e) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    const productId = uuidValidate(dataId)
      ? dataId
      : parseInt(e.currentTarget.getAttribute('data-id'), 10);
    const mutationData = {
      variables: { id: productId },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'ProductType',
        recordId: productId,
        mutationType: 'DELETE',
      },
      update: updateFunctions.productDelete,
    };
    mutationData.optimisticResponse = updateFunctions.optimisticNew({
      mutationName: 'productDelete',
      mutationData,
    });
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await productDelete(mutationData);
        toastSuccess('Product delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Product delete failed');
      } finally {
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
      }
    } else {
      productDelete(mutationData);
      toastWarning(`Product delete ok locally. Go online to make permanent`);
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
    }
  };

  const getProductCategoryName = (row) => {
    const {
      productCategory: { name: productCategoryName },
    } = row;
    return productCategoryName;
  };

  const renderSupplierCatalogItemsCell = (cell) => {
    const items = cell.getValue();
    return (
      <span>
        {items.map((item) => {
          const {
            id,
            quantity,
            supplierCatalogItem: { description },
          } = item;
          return (
            <span key={id}>
              {`${quantity} X ${description}`}
              <br />
            </span>
          );
        })}
      </span>
    );
  };

  const renderProductProductProductAssembliesCell = (cell) => {
    const items = cell.getValue();
    return (
      <span>
        {items.map((item) => {
          const {
            id,
            quantity,
            productSubassembly: { name },
          } = item;
          return (
            <span key={id}>
              {`${quantity} X ${name}`}
              <br />
            </span>
          );
        })}
      </span>
    );
  };

  const parentColumns = [
    {
      header: 'Product Category',
      id: 'productCategoryName',
      accessorFn: (row) => getProductCategoryName(row),
      filterType: 'dropdown',
    },
    {
      header: 'Name',
      accessorKey: 'name',
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
    {
      header: 'Subassemblies',
      accessorKey: 'productProductProductAssemblies',
      cell: renderProductProductProductAssembliesCell,
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
    {
      header: 'Catalog Items',
      accessorKey: 'productSupplierCatalogItems',
      cell: renderSupplierCatalogItemsCell,
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
  ];

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm={12}>
          <div className="float-none">
            <div className="float-start">
              <h1 className="h3 mb-3">Products</h1>
            </div>
            <div className="float-end">
              <Button
                className="me-2"
                variant="primary"
                onClick={() => pageRefetch()}
                disabled={!settingsOnline}
              >
                Refresh
              </Button>
              <LinkContainer to="/products/new">
                <Button variant="primary">New Product</Button>
              </LinkContainer>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <ReactTable
                rootName="product"
                parentColumns={parentColumns}
                data={pageData.productList}
                doShow={false}
                doDelete={currentUser?.perms?.administrator}
                handleDelete={productDeleteClicked}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default ProductList;

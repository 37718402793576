const defaultOptions = {
  labels: [],
  chart: {
    toolbar: {
      show: true,
    },
  },
  title: { style: { fontSize: '18px' } },
  plotOptions: {
    radialBar: {
      offsetY: 0,
      startAngle: 0,
      endAngle: 270,
      hollow: {
        margin: 5,
        size: '30%',
        background: 'transparent',
        image: undefined,
      },
      dataLabels: {
        name: {
          show: false,
        },
        value: {
          show: false,
        },
      },
      barLabels: {
        enabled: true,
        useSeriesColors: true,
        offsetX: -8,
        fontSize: '16px',
        formatter: (seriesName, opts) =>
          `${seriesName}: ${opts.w.globals.series[opts.seriesIndex]}%`,
      },
    },
  },
  colors: ['#3B82EC', '#4BBF73'],
  // not sure this responsive does anything
  responsive: [
    {
      breakpoint: 980,
      options: {
        legend: {
          show: false,
        },
        barLabels: {
          fontSize: '12px',
        },
      },
    },
  ],
};

export default defaultOptions;

import { useEffect, useRef } from 'react';
import { useLocation, Outlet } from 'react-router';

export default function FluidAndScrollLayout() {
  const { pathname } = useLocation();
  const scrollableDivRef = useRef(null);
  useEffect(() => {
    scrollableDivRef.current.scrollTo(0, 0);
  }, [pathname, scrollableDivRef]);

  return (
    <div
      style={{
        height: 'calc(100vh - 80px - 8px)', // - header - tiny footer
      }}
    >
      <div
        style={{
          height: '100%',
          overflowY: 'scroll',
        }}
        ref={scrollableDivRef}
      >
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

const defaultOptions = {
  chart: {
    toolbar: {
      show: true,
    },
  },
  title: { style: { fontSize: '18px' } },
  yaxis: {
    stepSize: 20,
    min: 0,
    max: 100,
  },
  xaxis: {
    categories: ['A', 'B', 'C'],
  },
};

export default defaultOptions;

import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import proj4 from 'proj4';
import L from 'leaflet';
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';

import ErrorBoundaryApp from './layouts/error_boundary_app';
import Header from './layouts/header';

import AppRoutes from './app_routes';

// maps
require('leaflet/dist/leaflet.css');
// toasts // css no longer requried version 11
// require('react-toastify/dist/ReactToastify.css');
// datetime picker
require('react-datetime/css/react-datetime.css');
require('react-bootstrap-typeahead/css/Typeahead.bs5.css');
require('./css/local.css');
// image gallery
require('react-image-gallery/styles/css/image-gallery.css');

proj4.defs(
  'EPSG:2105',
  '+title=NZGD2000 +proj=tmerc +lat_0=-36.8797222222222 +lon_0=174.764166666667 +k=0.9999 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs'
);

// TODO leaflet doesn't show markers when imported with webpack otherwise.
// https://github.com/PaulLeCam/react-leaflet/issues/255
delete L.Icon.Default.prototype._getIconUrl; // eslint-disable-line no-underscore-dangle
L.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
});

const App = () => {
  const authIsAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return (
    <ErrorBoundaryApp>
      <ToastContainer />
      {authIsAuthenticated && <Header />}
      <AppRoutes />
    </ErrorBoundaryApp>
  );
};

export default App;
